import { color } from 'hooks/Utils/color'
import { replaceSpecialCharacters } from 'hooks/useRenderText/useRenderText'
import { fetchTranslateText } from 'hooks/useTranslationText/useTranslationText'
import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

const TextTranslate = ({
    text,
    style = {}
}) => {

    const [textTranslate, setTextTranslate] = useState(null)
    const [langTranslate, setLangTranslate] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const translateText = async (to) => {
        try {
            if (!isLoading) {
                setIsLoading(true)
                setLangTranslate(to)
                let texteNormalise = replaceSpecialCharacters(text);
                const translatedText = await fetchTranslateText(texteNormalise, to)
                setIsLoading(false)
                setTextTranslate(translatedText)
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error Translate text:', error);
        }
    }

    const textFormatter = (text) => {
        if (text) {
            // Define a regular expression to match the pattern @[name](number)
            const regex = /@\[([^\]]+)\]\((\d+)\)/g;

            // Replace the matched pattern with a React component
            const formattedText = text.replace(regex, (match, name, number) => {
               return `@${name}`
            });
            return <span>{formattedText}</span>;
        }
    };

    const Hashtag = ({ tag }) => {
        return (
            <Link to={`/search/${tag}`}
                style={{
                    color: color.primary,
                    textDecoration: 'none',
                    fontWeight: '600'
                }}>
                #{tag}{' '}
            </Link>
        );
    }

    return (
        <div>
            <div
                style={{
                    flexDirection: 'row',
                    alignItems: 'center'
                }}>
                <span onClick={() => translateText('fr')} style={{ fontWeight: '600', color: langTranslate === 'fr' ? color.primary : '#808080' }}>
                    FR
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('en')} style={{ fontWeight: '600', color: langTranslate === 'en' ? color.primary : '#808080' }}>
                    EN
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('ru')} style={{ fontWeight: '600', color: langTranslate === 'ru' ? color.primary : '#808080' }}>
                    RU
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('de')} style={{ fontWeight: '600', color: langTranslate === 'de' ? color.primary : '#808080' }}>
                    DE
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('es')} style={{ fontWeight: '600', color: langTranslate === 'es' ? color.primary : '#808080' }}>
                    ES
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('pt')} style={{ fontWeight: '600', color: langTranslate === 'pt' ? color.primary : '#808080' }}>
                    PT
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('zh')} style={{ fontWeight: '600', color: langTranslate === 'zh' ? color.primary : '#808080' }}>
                    CN
                </span>
                <span style={{ fontWeight: 'bold', color: color.dark }}> · </span>
                <span onClick={() => translateText('ar')} style={{ fontWeight: '600', color: langTranslate === 'ar' ? color.primary : '#808080' }}>
                    RA
                </span>
            </div>
            <p>
                {textFormatter(textTranslate?.replaceAll('&#39;', "'"))}
            </p>
        </div>
    )
}

export default memo(TextTranslate)