import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import styles from './RecentOpinions.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from 'api/api'
import { useAccessToken, useRecentReducer } from 'hooks/ReducerHooks/ReducerHooks'
import useReducerRecentAction from 'hooks/ReducerHooksAction/useReducerRecentAction'
import Opinion from 'components/Opinion/Opinion'
import { AiFillPlusSquare } from "react-icons/ai";


const RecentOpinions = () => {

    // STATE
    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: RecentData } = useRecentReducer()
    const { REFRESH_RECENT_OPINIONS, DELETE_RECENT_OPINION, MUTED_USER_RECENT_OPINION, ADD_RECENT_OPINIONS, UPDATE_RECENT_OPINION } = useReducerRecentAction()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(false)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        try {
            setIsRefresh(true)
            const request = await api(`api/opinions/v2/public?orderBy=sond_publishing&page=1`, 'GET', null, accessToken)
            const response = await request.json()
            console.log('Response fetch opinions recent:', response)
            setIsRefresh(false)
            setIsLoading(false)
            if (request.status === 200) {
                if (response.success) {
                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    if (response?.opinions?.data?.length === 0) {
                        setIsEmpty(true)
                        return
                    } 
                    setPage(2)
                    REFRESH_RECENT_OPINIONS(response?.opinions?.data)
                }
            }
        } catch (error) {
            setIsRefresh(false)
            setTimeout(() => {
                fetchOpinions()
            }, 2000);
            console.error(error);
        }
    };



    const loadMoreOpinions = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true)
                const request = await api(`api/opinions/v2/public?orderBy=sond_publishing&page=${page}`, 'GET', null, accessToken)
                const response = await request.json()
                console.log('Response fetch opinions random:', response)
                setIsLoading(false)
                if (request.status === 200) {
                    if (response.success) {
                        if (response?.opinions?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        ADD_RECENT_OPINIONS(response?.opinions?.data)
                        setPage(item => item + 1)

                    }
                }
            }
        } catch (error) {
            setTimeout(() => {
                loadMoreOpinions()
            }, 2000);
            console.error(error);
        }
    };


    if (isRefresh) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '30px',
                            height: '30px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '5%' }}>
                        {t('loading_recent')}
                    </h6>
                </div>
            </div>
        )
    }

    if (isEmpty) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <AiFillPlusSquare color='#c0c0c0' size={52} className='mx-auto' />
                    <h6 style={{ color: '#808080', marginTop: '5%' }}>
                        {t('be_the_first_to_publish_a_post')}
                    </h6>
                </div>
            </div>
        )
    }

    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '5%' }}>
                <div style={{ textAlign: 'center' }}>
                <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '30px',
                            height: '30px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }

    return (
        <div id="RecentScrollableDiv" className={styles.page}>
            <InfiniteScroll
                dataLength={RecentData?.opinions.length}
                next={loadMoreOpinions}
                hasMore={hasMore}
                loader={renderLoader()}
                scrollableTarget="RecentScrollableDiv"
                scrollThreshold={0.9}
                style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingBottom: '100px',
                }}>
                {RecentData?.opinions.map((item, index) => {
                    return (
                        <Opinion
                            key={index}
                            item={item}
                            opinion={item}
                            index={index}
                            updateOpinion={UPDATE_RECENT_OPINION}
                            deleteOpinion={DELETE_RECENT_OPINION}
                            handleMuteUser={MUTED_USER_RECENT_OPINION}
                            sourceFrom={'Public'}
                        />
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default RecentOpinions