import Header from 'components/Header/Header'
import React from 'react'
import styles from './SpeechToTranslate.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useState } from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { api } from 'api/api'
import { Alert, Button, Stack } from '@mui/material'
import axios from 'axios'
import { OPEN_AI_API_KEY, OPEN_AI_AUDIO_FORMAT } from 'constants/OpenAI'
import { Spinner } from 'react-bootstrap'
import { IoSearchSharp } from 'react-icons/io5'
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'
import { color } from 'hooks/Utils/color'
import { FaRegFileAudio } from 'react-icons/fa'
import SondAudio from 'components/Opinion/OpinionBody/SondAudio/SondAudio'
import { FiPaperclip } from 'react-icons/fi'
import { BsFillVolumeUpFill } from 'react-icons/bs'
import { MdDeleteOutline } from 'react-icons/md'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { FiMenu } from 'react-icons/fi'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SpeechToTranslate = () => {

  const { t, i18n } = useTranslation()
  const accessToken = useAccessToken()
  const { data: authReducerState } = useAuthReducer()
  const navigate = useNavigate()


  const type = 'speech-to-translate'
  const [openAIConfig, setOpenAIConfig] = useState({});
  const [chatParentID, setChatParentID] = useState(null)
  const [prompt, setPrompt] = useState('')

  const [messages, setMessages] = useState([])
  const [pageMessage, setPageMessage] = useState(1)
  const [isFetchingMessage, setIsFetchingMessage] = useState(false)
  const [hasMorePageMessage, setHasMorePageMessage] = useState(false)
  const [isLoadingMoreMessage, setIsLoadingMoreMessage] = useState(false)

  const [isLoading, setIsLoading] = useState('')
  const [error, setError] = useState('')

  const [searchText, setSearchText] = useState('')
  const [searchHistories, setSearchHistories] = useState([])

  const [histories, setHistories] = useState([])
  const [page, setPage] = useState(1)
  const [hasMorePage, setHasMorePage] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)


  const contentRef = useRef(null)
  const actionSheetMenu = useRef(null)
  const actionSheetHistory = useRef(null)

  // TRANSCRIPTION STATE
  const [audioFile, setAudioFile] = useState(null);
  const [recording, setRecording] = useState();
  const [statusAsync, setStatusAsync] = useState({})

  const [isTransLoading, setIsTransLoading] = useState('')
  const [isRecording, setIsRecording] = useState(false);


  
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const goBack = () => navigate(-1)



  useEffect(() => {
    fetchHistory()
    fetchOpenAIConfig()
  }, []);

  const fetchOpenAIConfig = async () => {
    try {
      const request = await api('api/open_ai/transaction/quota', 'GET', {}, accessToken)
      const response = await request.json()
      console.log('Response fetch Open AI  Config:', response)
      if (request?.status === 200) {
        if (response?.success) {
          setOpenAIConfig(response?.data)
        }
      }
    } catch (error) {
      console.error('Error fetch user info:', error)
      throw new Error(error)
    }
  }


  const handleVerifyAccess = (e) => {
    e.preventDefault();
    try {
      if (!openAIConfig?.open_ai_enabled) {
        setError(t('youAreNotAuthorizedToUseThisService'))
      } else if (!openAIConfig?.authorized) {
        setError(t('youHaveReachedTheAuthorizedLimit'))
      } else {
        handleCallOpenAPI()
      }
    } catch (error) {
      console.error(error);
    }
  }


  const handleCallOpenAPI = async () => {
    if (audioFile?.uri && !isLoading) {
      try {
        setError('')
        setMessages([...messages, {
          role: "user",
          content: audioFile?.name,
        }])

        const formData = new FormData();
        formData.append('model', 'whisper-1')
        formData.append('response_format', 'json')
        formData.append('file', audioFile?.file)

        onScrollToBottom()
        setIsLoading(true)  
        setPrompt('')

        const response = await axios.post('https://api.openai.com/v1/audio/translations', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${OPEN_AI_API_KEY}`,
          },
          onUploadProgress: progressEvent => {
            let { loaded, total } = progressEvent;
            let pourcentage = Math.round((loaded / total) * 100)
            console.log(Math.round((loaded / total) * 100), '% publication en cours...')
          },
        });
        console.log(response?.data)
        incrementQuota()
        setMessages(messages => [...messages, {
          role: "assistant",
          content: response?.data?.text
        }])
        setIsLoading(false)
        setAudioFile(null)
        setTimeout(() => {
          onScrollToBottom()
        }, 100);

        // SAVE COMPLETIONS HISTORY
        const completionHistory = {
          parent_id: chatParentID,   // <nullable|integer>,
          request_content: audioFile?.name,   // <nullable|string>,
          request_role: 'user',   // <required|string>,
          response_content: response?.data?.text, //<required|string>,
          response_role: 'assistant',   // <required|string>,
          type: type,
          // attachment: null,  // <nullable|file>,
          // attachement_type: '',  // <nullable|string>,
          // attachement_filename: '',  // <nullable|string>,
          // attachement_duration: '',  // <nullable|number>,
        }
        handleSaveHistory(completionHistory)

      } catch (error) {
        if (error?.message === 'Network Error') {
          setError(t('login:messageNoInternetConnection'))
        } else {
          setError(t('anErrorHasOccurredPleaseTryAgain'))
        }
        setIsLoading(false)
        console.error('Error calling OpenAI API:', error?.response?.data || error?.message);

      }
    }
  };


  const incrementQuota = async () => {
    try {
      const request = await api('api/open_ai/transaction/increment', 'POST', {}, accessToken)
      const response = await request?.json()
      fetchOpenAIConfig()
    } catch (error) {
      console.error(error);
    }
  }


  //=================== HISTORY MANAGEMENT ========================//
  const handleSaveHistory = async (data) => {
    try {
      const request = await api('api/ai_chat_messages', 'POST', data, accessToken)
      const response = await request?.json()
      // console.log('Save history response:', response)
      if (response?.success) {
        if (!chatParentID) {
          setChatParentID(response?.data?.request?.chat_id)
        }
        fetchHistory()
      }
    } catch (error) {
      handleSaveHistory()
      console.error('Save message error:', error);
    }
  }


  const fetchHistory = async () => {
    try {
      setIsFetching(true)
      const request = await api(`api/ai_chat_messages/history?chat_type=${type}&page=${1}`, 'GET', {}, accessToken)
      const response = await request?.json()
      setIsFetching(false)
      // console.log('fetch message response:', response?.data)
      if (response?.success) {
        if (response?.data?.next_page_url) {
          setHasMorePage(true)
        } else {
          setHasMorePage(false)
        }
        setPage(2)
        setHistories(response?.data?.data)
        setSearchHistories(response?.data?.data)
      }
    } catch (error) {
      fetchHistory()
      console.error('Save message error:', error);
    }
  }

  const onScrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };


  
  const handleLoadChat = async (id) => {
    try {
        setIsFetchingMessage(true)
        setChatParentID(id)
        const request = await api(`api/ai_chat_messages/${id}/messages?page=${1}`, 'GET', {}, accessToken)
        const response = await request?.json()
        setIsFetchingMessage(false)
        // console.log('fetch conversation response:', response?.data?.data)
        if (response?.success) {
            if (response?.data?.next_page_url) {
                setHasMorePageMessage(true)
            } else {
                setHasMorePageMessage(false)
            }
            setPageMessage(2)
            const dataFormated = response?.data?.data?.map(history => ({
                role: history?.chat_role,
                content: history?.chat_content
            }))
            setMessages(dataFormated)
        }
    } catch (error) {
        handleLoadChat(id)
        console.error(error);
    }
}


const handleLoadMoreChat = async () => {
    try {
        if (hasMorePageMessage && !isLoadingMoreMessage) {
            setIsLoadingMoreMessage(true)
            const request = await api(`api/ai_chat_messages/${chatParentID}/messages?page=${pageMessage}`, 'GET', {}, accessToken)
            const response = await request?.json()
            setIsLoadingMoreMessage(false)
            // console.log('fetch conversation response:', response?.data?.data)
            if (response?.success) {
                if (response?.data?.next_page_url) {
                    setHasMorePageMessage(true)
                } else {
                    setHasMorePageMessage(false)
                }
                setPageMessage(pageMessage => pageMessage + 1)
                const dataFormated = response?.data?.data?.map(history => ({
                    role: history?.chat_role,
                    content: history?.chat_content
                }))
                setMessages(dataFormated)
            }
        }
    } catch (error) {
        handleLoadMoreChat()
        console.error(error);
    }
}


  const handleFileChange = (e) => {
    try {
      if (e.target.files) {
        const file = e.target.files[0]
        const fileUri = window.URL.createObjectURL(file)
        const extension = file?.name?.slice(file?.name?.lastIndexOf('.') + 1)
        
        if (!OPEN_AI_AUDIO_FORMAT?.includes(extension)) {
          setError(
            i18n?.language === 'fr' ?
              `Ce format audio (${extension}) n'est pas pris en charge.`
              :
              `This audio format (${extension}) is not supported.`
          )
          return
        }

        if (file.type.split("/")[0] === 'audio') {
          const data = {
            file,
            name: file?.name,
            uri: fileUri,
            size: file?.size,
            type: 'audio',
            extension: file?.type?.split("/")[1],
            mime_type: file?.type
          }
          setAudioFile(data)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const RenderAlert = () => {
    if (error) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2} style={{ marginBottom: '20px' }}>
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Stack>
        )
    }
}

const Toolbar = () => {
    return (
        <div className='header-componant'>
            <button onClick={goBack} className='button-back' >
                <MdOutlineKeyboardBackspace size={32} style={{ width: 32, height: 32 }} />
            </button>
            <h5 className='title'>
            {t('speechToTranslateInEnglish')}
            </h5>
            <button onClick={handleClickOpen}>
                <FiMenu className={styles.menuIcon} />
            </button>
        </div>
    )
}

  return (
    <div className={styles.page} >
       <Toolbar />
      <div ref={contentRef} className={styles.content}>
        {RenderAlert()}
        {messages.map((message, index) => {
          return (
            <div key={index.toString()} style={{ paddingBottom: '20px' }}>
              <div className={styles.userContainer}>
                {message?.role === 'user' ?
                  <ProfilePicture uri={authReducerState?.user?.profile?.prof_picture} size={40} />
                  :
                  <img src={require('assets/icons/chat_gpt.png')} width={40} alt='' style={{ width: '40px', heigth: '40px', borderRadius: '40px' }} />
                }
                <h6 style={{ fontWeight: '600', marginLeft: '10px', marginTop: '10px' }}>
                  {message?.role === 'user' ? `${authReducerState?.user?.user_surname} ${authReducerState?.user?.user_name}` : 'GPT-3.5 Turbo'}
                </h6>
              </div>
              {message?.content}
            </div>
          )
        })}
        {isLoading &&
          <div style={{ paddingBottom: '20px' }}>
            <div className={styles.userContainer}>
              <img src={require('assets/icons/chat_gpt.png')} width={40} alt='' style={{ width: '40px', heigth: '40px', borderRadius: '40px' }} />
              <h6 style={{ fontWeight: '600', marginLeft: '10px', marginTop: '10px' }}>
                GPT-3.5 Turbo
              </h6>
            </div>
            <span style={{ color: color.primary, fontWeight: '500' }}>
              Translation...
            </span>
          </div>
        }
      </div>
      <form onSubmit={handleVerifyAccess}>
        <div className={styles.search}>
          {audioFile ?
            <div className={styles.audioPlayer}>
              <button
                onClick={() => setAudioFile(null)}
                style={{ backgroundColor: color.danger }}
                className={styles.searchButton}>
                <MdDeleteOutline
                  size={20}
                  color='white'
                />
              </button>
              <audio controls style={{ width: '90%', height: '40px', border: `1px #C0C0C0 solid`, borderRadius: '50px', }}>
                <source src={audioFile?.uri} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
            :
            <label
              onChange={(e) => handleFileChange(e)}
              htmlFor="input-audio"
              className={styles.inputFile}>
              <input accept="audio/*" name="" type="file" id="input-audio" hidden />
              <FiPaperclip color={color.primary} className={styles.inputIcon} />
              <span className={styles.inputFileText}>
                Sélection l'audio à transcrire.
              </span>
            </label>
          }

          {/* <div style={{width: '90%', backgroundColor: color.primary , height: '35px' }}>
            {audioFile?.file?.name}
            <SondAudio uri={audioFile} />
          </div> */}

          {/* <input
            placeholder={`${t('search')}...`}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            type='file'
            className={styles.searchInput}
          /> */}
          <button
            onClick={handleVerifyAccess}
            className={styles.searchButton}>
            {!isLoading ?
              <IoSearchSharp
                size={20}
                color='white'
              />
              :
              <Spinner
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border"
                variant='light'
              />}
          </button>
        </div>

        
        <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t('history')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {histories.map((history, index) => {
                            return (
                                <div key={index?.toString()}
                                    onClick={() => {
                                        handleClose()
                                        handleLoadChat(history?.chat_id)
                                    }}
                                    className={styles.history}>
                                    <h6 className={styles.historyTitle}>
                                        {history?.chat_content}
                                    </h6>
                                    <span className={styles.historyContent}>
                                        {history?.chat_response.length < 50 ? history?.chat_response.length : `${history?.chat_response?.slice(0, 70)}...`}
                                    </span>
                                </div>
                            )
                        })}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
      </form>
    </div>
  )
}

export default SpeechToTranslate