import React, { useEffect, useState } from 'react'
import background from 'assets/images/bg_clips.jpg'
import { MdLiveTv } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import LiveCreate from './Componants/LiveCreate/LiveCreate'
import { useTranslation } from 'react-i18next'
import { firestoreDB } from 'services/firebase'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import firestorekeys from 'constants/firestorekeys'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { MdAdsClick } from "react-icons/md";
import avatar from 'assets/images/background_opinion.jpg'
import { FiChevronsDown } from "react-icons/fi";
import { scheme } from 'constants/env'

const Live = () => {


    const { t, i18n } = useTranslation()
    const { data: authContextState } = useAuthReducer()
    const navigate = useNavigate()

    const [lives, setLives] = useState([])
    const [showCreateLive, setShowCreateLive] = useState(false)

    const handleShowCreateLive = () => setShowCreateLive(oldValue => !oldValue)

    const goToJoinLive = (live) => {
        console.log(live)
        navigate('/')
        // navigate(`live/${live?.live_channel_name}`, {
        //     state: live
        // })
    }

    useEffect(() => {
        const q = query(
            collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels
            ),
            where("live_finished", "==", false),
            orderBy("live_id", "desc")
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setLives(data)
        });
        return () => {
            unsubscribe();
        }
    }, []);



    const createLiveView = () => {
        return (
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                className='flex items-center justify-center h-screen bg-black border-b-4 border-b-gray-200'>

                <div className='text-center'>
                    <MdLiveTv
                        color='white'
                        size={68}
                        className='mx-auto'
                    />
                    <p className='text-white my-3 text-center'>
                        {t('you_dont_have_a_live_in_progress')}
                    </p>
                    <Link onClick={handleShowCreateLive} className='text-white font-bold text-center bg-red-600 py-2 px-3 ms-auto rounded-full'>
                        {t('start_a_live')}
                    </Link>
                    {lives?.length != 0 &&
                        <div className='w-full font-bold text-white text-center mt-10'>
                            {lives?.length} live(s) en cours
                            <p className='font-normal mt-3'>
                                {i18n.language == 'en'
                                    ? 'Scroll to see the list'
                                    : 'Défiler pour voir la liste'
                                }
                            </p>
                            <FiChevronsDown size={32} className='mx-auto' />
                        </div>
                    }
                </div>

            </div>
        )
    }


    return (
        <div className='h-screen overflow-auto bg-black'>
            {lives?.length == 0 && createLiveView()}
            {lives.map((item, index) => {
                return (
                    <div key={index}>
                        {index == 0 && createLiveView()}
                        <Link
                            key={index}
                            to={`/live/${item?.live_channel_name}`}
                            state={{
                                live_data: item
                            }}
                            className='w-full'>
                            <div
                                key={index}
                                style={{
                                    backgroundImage: `url(${item?.live_thumbnail || background})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                                className='flex items-end justify-center h-screen bg-black opacity-95 border-b-4 border-b-gray-500'>
                                <div className='w-full px-3 mb-20 md:mb-10'>
                                    <div className='w-full mb-44'>
                                        <MdAdsClick
                                            color='white'
                                            size={30}
                                            className='mx-auto'
                                        />
                                        <p className='text-white my-3 text-center font-bold'>
                                            {i18n.language == 'en'
                                                ? "Join the live"
                                                : "Joindre le live"}
                                        </p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-white mb-3'>
                                            {item?.live_description}
                                        </p>
                                        <div className="flex p-1 items-center w-72 rounded-full me-3 border border-neutral-700">
                                            <img src={item?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
                                            <div className="ps-2 pe-4">
                                                <h3 className="text-white font-bold">{item?.user?.user_surname} {item?.user?.user_name}</h3>
                                                <span className="text-xs text-white ">@{item?.user?.user_username}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    </div>
                )
            })}


            {/* CREATE LIVE */}
            <LiveCreate
                visible={showCreateLive}
                onChangeVisible={handleShowCreateLive}
            />


        </div>
    )
}

export default Live