import React, { memo, useEffect } from 'react'
import { MdAddBox, MdGroups, MdLiveTv } from 'react-icons/md'
import { color } from '../../../hooks/Utils/color'
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import moment from 'moment';
import 'moment/locale/fr';
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks';
import { FaUsers } from 'react-icons/fa';
import { AiFillLike, AiFillMessage } from 'react-icons/ai';
import { IoHandLeftSharp } from 'react-icons/io5';
import { BsFillCheckSquareFill, BsTelegram } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../api/api';
import useReducerNotifAction from '../../../hooks/ReducerHooksAction/useReducerNotifAction';
import { useTranslation } from 'react-i18next';
import { TextWithMentionsAndHashtags } from 'hooks/useRenderText/useRenderText';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { scheme } from 'constants/env';
import { firestoreDB } from 'services/firebase';
import firestorekeys from 'constants/firestorekeys';

const NotifItem = ({
  notif
}) => {

  const { t } = useTranslation()
  const accessToken = useAccessToken()
  const { UPDATE_NOTIFICATION } = useReducerNotifAction()
  const navigate = useNavigate()
  const MAX_LENGTH = 100;
  const { data: authData } = useAuthReducer()
  const timeAgo = moment(notif?.notif_created).locale(authData?.language).fromNow();


  const goToProfile = () => {
    navigate(`/profiles/user/${authData?.user?.user_username}`)
  }

  const goToViewOpinion = () => {
    navigate(`/opinions/${notif?.opinion?.sond_hash}`)
  }

  const goToTransaction = () => {
    navigate(`/profiles/user/${authData?.user?.user_username}`)
  }



  const goToViewNotification = () => {
    try {
      switch (notif?.notif_type) {
        case 0:
          // navigation.navigate('ViewOpinion', {
          //   sond_id: notif_opid,
          //   sourceFrom: 'Notification',
          // })
          navigate(`/opinions/${notif?.opinion?.sond_hash}`)
          break;
        case 1:
          // navigation.navigate('Audience', {
          //   user_id: authContextState?.user?.user_id,
          //   user_name: authContextState?.user?.user_name,
          //   user_surname: authContextState?.user?.user_surname,
          //   user_username: authContextState?.user?.user_username,
          //   prof_picture: authContextState?.user?.profile?.prof_picture,
          //   user_badge_goal: authContextState?.user?.user_badge_goal,
          //   user_badge_business: authContextState?.user?.user_badge_business,
          //   user_badge_hobby: authContextState?.user?.user_badge_hobby,
          //   user_badge_food: authContextState?.user?.user_badge_food,
          //   user_badge_color: authContextState?.user?.user_badge_color,
          //   user_match_value: authContextState?.user?.user_match_value,
          //   sourceFrom: 'notification',
          // })
          break;
        case 2:
          // navigation.navigate('DetailAgora', {
          //   ago_id: notificationItem?.notif_agoid,
          // })
          break;
        case 3:
          // const chat_id = generateChatId(
          //   notificationItem?.notif_conversation_type,
          //   notificationItem?.notif_conversation_id || notif_from,
          //   authContextState?.user?.user_id,
          // )
          // if (chat_id) {

          //   navigation.push('OneToOneMessage', {
          //     chat_id: chat_id,
          //     user_to_tchat_with_id: notificationItem?.notif_conversation_id || notif_from,
          //     // user_to_tchat_with: {
          //     //   user_id: notif_from,
          //     //   user_surname: user_from?.user_surname,
          //     //   user_name: user_from?.user_name,
          //     //   user_username: notificationItem?.user_from?.user_username ,
          //     //   user_civility: user_from?.user_civility,
          //     //   prof_picture: user_from?.user_picture,

          //     //   user_badge_goal: user_from?.user_badge_goal,
          //     //   user_badge_business: user_from?.user_badge_business,
          //     //   user_badge_hobby: user_from?.user_badge_hobby,
          //     //   user_badge_food: user_from?.user_badge_food,
          //     //   user_badge_color: user_from?.user_badge_color,
          //     //   user_match_value: user_from?.user_match_value,
          //     //   connection_status: false,
          //     // },
          //     conversation: {
          //       conv_id: notificationItem?.notif_conversation_id || notif_from,
          //       conv_type: notificationItem?.notif_conversation_type
          //     },
          //     sent_messages: [],
          //     sourceFrom: 'Notification',
          //   })
          // }
          break;
        case 4:
          // navigation.navigate('ProfileBalance')
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 5:
          // navigation?.push('Profil', {
          //   user_id: notif_from,
          //   user_surname: user_from?.user_surname,
          //   user_name: user_from?.user_name,
          // })
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 6:
          // navigation?.push('Profil', {
          //   user_id: notif_from,
          //   user_surname: user_from?.user_surname,
          //   user_name: user_from?.user_name,
          // })
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 7:
          // navigation.navigate('ViewOpinion', {
          //   sond_id: notif_opid,
          //   sourceFrom: 'Notification',
          // })

          navigate(`/opinions/${notif?.opinion?.sond_hash}`)
          break;
        case 8:
          // navigation.navigate('ViewOpinion', {
          //   sond_id: notif_opid,
          //   sourceFrom: 'Notification',
          // })

          navigate(`/opinions/${notif?.opinion?.sond_hash}`)
          break;
        case 9:
          // navigation.navigate('ViewOpinion', {
          //   sond_id: notif_opid,
          //   sourceFrom: 'Notification',
          // })

          navigate(`/opinions/${notif?.opinion?.sond_hash}`)
          break;
        case 10:
          // navigation.navigate('ViewOpinion', {
          //   sond_id: notif_opid,
          //   sourceFrom: 'Notification',
          // })

          navigate(`/opinions/${notif?.opinion?.sond_hash}`)
          break;
        case 11:
          // navigation.navigate('RequestDetails', { hr_req_id: notificationItem?.hr_request?.hr_req_id })
          break;
        case 12:
          // navigation.navigate('EventDetails', { event_id: notificationItem?.event?.event_id })
          break;
        case 13:
          // navigation.navigate('AdminRequests')
          break;
        case 14:
          // goToStory()
          break;
        case 15:
          // navigation?.push('Profil', {
          //   user_id: notif_from,
          //   user_surname: user_from?.user_surname,
          //   user_name: user_from?.user_name,
          // })
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 16:
          // navigation?.push('Profil', {
          //   user_id: notif_from,
          //   user_surname: user_from?.user_surname,
          //   user_name: user_from?.user_name,
          // })
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 17:
          // navigation?.push('Profil', {
          //   user_id: notif_from,
          //   user_surname: user_from?.user_surname,
          //   user_name: user_from?.user_name,
          // })
          navigate(`/profiles/user/${authData?.user?.user_username}`)
          break;
        case 18:
          // goToInMailMessage()
          break;
        case 19:
          // navigation.navigate('GroupInvitations')
          break;
        case 21:
          // console.log(notificationItem?.notif_stream_id)
          // navigation.navigate('LiveSearch', {
          //   live_id: notificationItem?.notif_stream_id
          // })
          fetchLive(notif?.notif_stream_id)
          break;

        default:
          console.log("Unknown notification type.");
      }
    } catch (error) {
      console.error('Error view notif', error);
    }
  }

  const fetchLive = async (live_id) => {
    try {
      const docRef = query(
        collection(firestoreDB, scheme, firestorekeys.lives, firestorekeys.channels),
        where('live_id', '==', live_id)
      );
      const docSnap = await getDocs(docRef);

      if (!docSnap.empty) {
        console.log('Live', docSnap.docs[0].data().live_channel_name)
        navigate(`/live/${docSnap.docs[0].data().live_channel_name}`)
      } else {
        console.log("No matching documents found.");
      }
    } catch (error) {
      console.error('Error fetch live:', error);
    }
  }

  const approveBuddy = async () => {

    try {
      const body = { user_id: notif?.notif_from }
      const request = await api(
        'api/profile/approve-request-buddy',
        'POST',
        body,
        accessToken,
      )
      const response = await request.json()
      console.log('Response approve buddy:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {

          UPDATE_NOTIFICATION({ ...notif, ...{ notif_state: true } })

          //   sendNotification(user_id, {
          //     title: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a vu votre profil.`,
          //     body: `${authStateContext?.user?.user_surname} ${authStateContext?.user?.user_name} a accepté votre demande.`,
          //     url: `https://myopinion.ci/profiles/user/${authStateContext?.user?.user_id}`
          //   })
        }
      }
    } catch ({ message }) {
      throw new Error(message)
    }
  }

  const cancelBuddy = async () => {
    try {
      const body = { user_id: notif?.notif_from }
      const request = await api(
        'api/profile/request-cancel-buddy',
        'DELETE',
        body,
        accessToken,
      )
      const response = await request.json()
      console.log('Response cancel buddy:', response)
      if (request.ok && request.status === 200) {
        if (response.success) {

          UPDATE_NOTIFICATION({ ...notif, ...{ notif_state: true } })

        }
      }
    } catch ({ message }) {
      throw new Error(message)
    }
  }


  const RenderHeaderNotif = () => {
    return (
      <div style={{ display: "flex", alignItems: 'center' }}>

        <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', width: '50px', height: '50px', marginRight: '5px' }}>
          <ProfilePicture
            user={notif?.user_from}
            uri={notif?.user_from?.profile?.prof_picture}
            size={40}
          />
        </div>
        <div className='w-52'>
          {authData?.language == 'fr' ? (
            <span style={{ fontSize: '14px' }}>
              {notif?.notif_content_fr?.split(" ")?.map((word, index) => {
                if (word === `${notif?.user_from?.user_surname}`) {
                  return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
                } else if (word === `${notif?.user_from?.user_name}`) {
                  return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
                } else {
                  return <span key={index}>{word} </span>;
                }
              })}
            </span>
          ) : (
            <span style={{ fontSize: '14px' }}>
              {notif?.notif_content_en?.split(" ")?.map((word, index) => {
                if (word === `${notif?.user_from?.user_surname}`) {
                  return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
                } else if (word === `${notif?.user_from?.user_name}`) {
                  return <span key={index} style={{ fontWeight: '600' }}>{word} </span>;
                } else {
                  return <span key={index}>{word} </span>;
                }
              })}
            </span>
          )}
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    if (notif?.notif_type == 0 || notif?.notif_type == 8 || notif?.notif_type == 9 || notif?.notif_type == 10 || notif?.notif_type == 11) {
      return (
        notif?.opinion?.sond_caption &&
        <div onClick={goToViewOpinion} style={{ cursor: 'pointer', display: "flex", alignItems: 'center', borderRadius: '5px', border: `0.5px #c0c0c0 solid`, marginTop: '10px', padding: '5px' }}>

          {(
            notif?.opinion?.sond_picture &&
            notif?.opinion?.sond_picture !== "NULL" &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mp4' &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'avi' &&
            notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mov'
          ) ?
            <div>
              {notif?.opinion?.sond_picture && notif?.opinion?.sond_picture !== "NULL" ?
                <img src={notif?.opinion?.sond_picture}
                  width={300}
                  // height={100}
                  alt=''
                  style={{
                    margin: '0px',
                    padding: '0px',
                    cursor: 'pointer',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: '1px'
                  }} />
                : null}
            </div>
            : null}

          <span style={{ fontSize: '13px', marginLeft: '10px' }}>
            <TextWithMentionsAndHashtags
              text={notif?.opinion?.sond_caption?.length < MAX_LENGTH ?
                notif?.opinion?.sond_caption
                :
                `${notif?.opinion?.sond_caption?.substring(0, MAX_LENGTH)}...`
              }
            />
          </span>

        </div>
      )
    }
    if (notif?.notif_type === 1) {
      return (
        (!notif?.notif_state && notif?.notif_content_fr.indexOf('approuvé'.toLowerCase()) === -1 && notif?.notif_content_fr.indexOf('fan'.toLowerCase()) === -1) &&
        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

          <button onClick={approveBuddy} style={{ width: '30%', paddingBottom: '4px', color: 'white', backgroundColor: color.primary, borderRadius: '3px', }}>
            <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('approve')}</span>
          </button>

          <button onClick={cancelBuddy} style={{ width: '30%', paddingBottom: '4px', color: color.primary, border: `1px ${color.primary} solid`, borderRadius: '3px', }}>
            <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('cancel')}</span>
          </button>

        </div>
      )
    }
  }


  const renderOpinionIcon = () => {
    if (notif?.notif_content_fr.indexOf('like'.toLowerCase()) > -1 || notif?.notif_content_fr.indexOf('aim'.toLowerCase()) > -1) {
      return (
        <AiFillLike size={30} color={color.info} />
      )
    }
    if (notif?.notif_content_fr.indexOf('like'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('comment'.toLowerCase()) ||
      notif?.notif_content_fr.indexOf('aim'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('comment'.toLowerCase())) {
      return (
        <AiFillLike size={30} color={color.info} />
      )
    }
    if (notif?.notif_content_fr.indexOf('voted'.toLowerCase()) > -1 || notif?.notif_content_fr.indexOf('voté'.toLowerCase()) > -1) {
      return (
        <BsFillCheckSquareFill size={30} color={'#28a745'} />
      )
    }

    // GESTION DES COMMENTAIRE
    if (notif?.notif_content_fr.indexOf('a commenté'.toLowerCase()) > -1 && notif?.notif_content_en.indexOf('commented'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('répondu à votre commentaire'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('commentaire sous votre opinion'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    if (notif?.notif_content_fr.indexOf('vous a mentionné dans un commentaire'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color={color.primary} />
      )
    }
    // GESTION DES TOP COMMENTAIRE
    if (notif?.notif_content_en.indexOf('you have a top comment'.toLowerCase()) > -1) {
      return (
        <BsTelegram size={30} color='#ffd700' />
      )
    }
    // GESTION DES SUGGESSIONS
    if (notif?.notif_content_fr.indexOf('top'.toLowerCase()) == -1 && notif?.notif_content_fr.indexOf('sugges'.toLowerCase()) > -1) {
      return (
        <IoHandLeftSharp size={30} color={color.info} />
      )
    }
    // GESTION DES TOP SUGGESSIONS 
    if (notif?.notif_content_fr.indexOf('top'.toLowerCase()) > -1 && notif?.notif_content_fr.indexOf('sugges'.toLowerCase()) > -1) {
      return (
        <IoHandLeftSharp size={30} color='#ffd700' />
      )
    }
    if (notif?.notif_type == 21) {
      return (
        <MdLiveTv size={30} color='red'
        />
      )
    } else {
      return (
        <MdAddBox size={30} color={color.primary} />
      )
    }


  }


  return (
    <div onClick={goToViewNotification} className='p-3 border border-gray-400 cursor-pointer'>
      <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
        <b style={{ fontSize: '11px' }} className='mb-2'>
          {timeAgo}
        </b>
      </div>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <div style={{ width: '30px', marginRight: '3px' }}>
          {renderOpinionIcon()}
        </div>
        <div>
          {RenderHeaderNotif()}
        </div>
      </div>
      {/* FOOTER */}
      <div>
        {renderFooter()}
      </div>
    </div>
  )

  if (notif?.notif_type === 0) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>

          <div style={{ width: '30px', marginRight: '3px' }}>

            {renderOpinionIcon()}
            {/* <MdAddBox size={30} color={color.secondary} /> */}
          </div>
          <div onClick={goToViewOpinion} style={{ cursor: 'pointer' }}>
            {RenderHeaderNotif()}
          </div>
        </div>


        {notif?.opinion?.sond_caption &&
          <div onClick={goToViewOpinion} style={{ cursor: 'pointer', display: "flex", alignItems: 'center', borderRadius: '5px', border: `0.5px #c0c0c0 solid`, marginTop: '10px', padding: '5px' }}>

            {(
              notif?.opinion?.sond_picture &&
              notif?.opinion?.sond_picture !== "NULL" &&
              notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mp4' &&
              notif?.opinion?.sond_picture?.split('.')?.pop() !== 'avi' &&
              notif?.opinion?.sond_picture?.split('.')?.pop() !== 'mov'
            ) ?
              <div>
                {notif?.opinion?.sond_picture && notif?.opinion?.sond_picture !== "NULL" ?
                  <img src={notif?.opinion?.sond_picture}
                    width={300}
                    // height={100}
                    alt=''
                    style={{
                      margin: '0px',
                      padding: '0px',
                      cursor: 'pointer',
                      objectFit: 'cover',
                      objectPosition: 'center',
                      borderRadius: '1px'
                    }} />
                  : null}
              </div>
              : null}

            <span style={{ fontSize: '13px', marginLeft: '10px' }}>
              <TextWithMentionsAndHashtags
                text={notif?.opinion?.sond_caption?.length < MAX_LENGTH ?
                  notif?.opinion?.sond_caption
                  :
                  `${notif?.opinion?.sond_caption?.substring(0, MAX_LENGTH)}...`
                }
              />
            </span>

          </div>
        }
      </div>
    )
  }

  if (notif?.notif_type === 1) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div onClick={goToProfile} style={{ display: "flex", alignItems: 'center', cursor: 'pointer' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <FaUsers size={30} color={color.secondary} />
          </div>
          {RenderHeaderNotif()}
        </div>

        {!notif?.notif_state && notif?.notif_content_fr.indexOf('approuvé'.toLowerCase()) === -1 && notif?.notif_content_fr.indexOf('fan'.toLowerCase()) === -1 ?
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

            <button onClick={approveBuddy} style={{ width: '30%', paddingBottom: '4px', color: 'white', backgroundColor: color.primary, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('approve')}</span>
            </button>

            <button onClick={cancelBuddy} style={{ width: '30%', paddingBottom: '4px', color: color.primary, border: `1px ${color.primary} solid`, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>{t('cancel')}</span>
            </button>

          </div>
          : null}

      </div>
    )
  }

  if (notif?.notif_type === 2) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>

          <div style={{ width: '30px', marginRight: '3px' }}>
            <MdGroups size={30} color={color.secondary} />
          </div>
          {RenderHeaderNotif()}
        </div>

        {/* {!notif?.notif_state &&
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

            <button style={{ width: '30%', paddingBottom: '4px', color: 'white', backgroundColor: color.primary, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>Approve</span>
            </button>

            <button style={{ width: '30%', paddingBottom: '4px', color: color.primary, border: `1px ${color.primary} solid`, borderRadius: '3px', }}>
              <span style={{ fontSize: '12px', fontWeight: '600', marginBottom: '10px' }}>Annuler</span>
            </button>

          </div>
        } */}

      </div>
    )
  }

  if (notif?.notif_type === 3) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <AiFillMessage size={30} color={color.primary} />
          </div>
          {RenderHeaderNotif()}
        </div>

      </div>
    )
  }

  if (notif?.notif_type === 4) {
    return (
      <div style={{ borderBottom: '5px #f1f1f1 solid', padding: '10px' }}>

        <div style={{ display: "flex", justifyContent: 'flex-end', marginBottom: '-5px' }}>
          <b style={{ fontSize: '11px' }}>
            {timeAgo}
          </b>
        </div>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <div style={{ width: '30px', marginRight: '3px' }}>
            <img src={notif?.recompense?.type?.type_recom_logo_url} width={30} height={25} alt='' />
          </div>
          <div onClick={goToTransaction} style={{ cursor: 'pointer' }}>
            {RenderHeaderNotif()}
          </div>
        </div>

      </div>
    )
  }

}

export default memo(NotifItem)