import React, { useEffect, useMemo, useState } from 'react'
import styles from './RequestSent.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate } from 'react-router-dom'
import Header from '../../../../components/Header/Header'
import { api } from '../../../../api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import RenderRequest from './RenderRequest/RenderRequest'
import Spinner from 'react-bootstrap/Spinner'
import { TbMailForward } from 'react-icons/tb';



const RequestSent = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()


    // REQUEST STATE
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    const [isFetching, setIsFetching] = useState(true)
    const [isLoading, setIsLoading] = useState(false)


    // NAVIGATION HANDLE
    const goBack = () => { navigate(-1) }

    useEffect(() => {
        const fetchRequest = async () => {
            try {
                const request = await api(`api/rh/hr_requests?page=1`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch request sent response:', response)
                setIsFetching(false)
                if (request?.status === 200) {
                    if (response?.success) {
                        if (response?.data?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        if (response?.data?.data?.length === 0) {
                            setIsEmpty(true)
                            return
                        }
                        setData(response?.data?.data)
                        setPage(2)
                    }
                }
            } catch (error) {
                fetchRequest()
                console.error('Fetch request sent error:', error);
            }
        }
        fetchRequest()
    }, [])


    const loadMoreRequest = async () => {
        try {
            setIsLoading(true)
            const request = await api(`api/rh/hr_requests?page=${page}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Fetch request sent response:', response)
            setIsLoading(false)
            if (request?.status === 200) {
                if (response?.success) {
                    if (response?.data?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    setData([...data, ...response?.data?.data])
                    setPage(page + 1)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Fetch request sent error:', error);
        }
    }



    // FETCHING DATA RENDER
    if (isFetching) {
        return (
            <div className={styles.container}>
                <Header title={t('requestSent')} goBack={goBack} />
                <div className={styles.loadingContent}>
                    <Spinner animation="border" variant="dark" />
                </div>
            </div>
        )
    }


    // FETCHING DATA RENDER
    if (isEmpty) {
        return (
            <div className={styles.container}>
                <Header title={t('requestSent')} goBack={goBack} />
                <div className={styles.emptyContainer}>
                    <div className={styles.emptyContent}>
                        <TbMailForward className={styles.iconEmptyContent} />
                    </div>
                    <h5 className={styles.textEmptyContent}>
                        {t('noRequestAvailable')}
                    </h5>
                </div>
            </div>
        )
    }


    const loaderFooter = () => {
        if (hasMore && isLoading) {
            return (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '50px'
                }}>
                    <Spinner animation="border" variant="dark" />
                </div>
            )
        }
    }

    return (
        <div className={styles.container}>
            <Header title={t('requestSent')} goBack={goBack} />
            <div className={styles.content}>
                <InfiniteScroll
                    dataLength={data?.length}
                    next={loadMoreRequest}
                    hasMore={hasMore}
                    loader={loaderFooter}
                    scrollableTarget='content'
                    height={'92vh'}>
                    {data.map((item, index) => (
                        <div key={index}>
                            <RenderRequest
                                request={item}
                                document={item?.document}
                                type={item?.type}
                            />
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default RequestSent