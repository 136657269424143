import React, { memo, useState } from 'react'
import { FaRegPlayCircle } from 'react-icons/fa'

const SondVideo = ({
    uri,
    thumbnail,
}) => {
    const [isLoaded, setIsLoaded] = useState(false)

    if (!isLoaded) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${thumbnail})`, 
                backgroundRepeat: 'no-repeat', 
                backgroundSize: 'cover', 
                width: '100%', 
                height: '400px'}}>
                <div onClick={() => setIsLoaded(true)} style={{cursor: 'pointer'}}>
                    <FaRegPlayCircle size={60} color='white' />
                </div>
            </div>
        )
    } else {
        return (
            <div className='sond-video'>
                <video width="100%" height="100%" controls >
                    <source src={uri} />
                </video>
            </div>
        )
    }
}

export default memo(SondVideo);