import React, { memo, useState } from 'react'
import styles from './CoverProfilePicture.module.css'
import { FaCamera } from 'react-icons/fa'
import { color } from 'hooks/Utils/color'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'
import Switch from "react-switch";

const CoverProfilePicture = ({
    user,
    type = 'profile',
    onRefresh = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()

    const [file, setFile] = useState({ uri: user?.profile?.prof_picture })
    const [fileUri, setFileUri] = useState(null)

    const [description, setDescription] = useState(null)
    const [publish, setPublish] = useState(false)
    const [error, setError] = useState(null)

    const [isVisible, setIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const open = () => {
        setIsVisible(true)
    }

    const handleClose = () => {
        setIsVisible(false)
        setFile('')
        setFileUri('')
    }

    const onTogglePublish = () => setPublish(value => !value)

    const handleSelectFile = (e) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            setFile(file)
            setFileUri(fileUri)
        }
    }


    const HandleUpdateProfilPicture = async () => {
        try {
            if (!file?.type) {
                setError({
                    image: i18n.language == 'en'
                        ? 'Select image'
                        : 'Selectionnez une image'
                })
                return
            }
            if (publish && !description) {
                setError({
                    description: i18n.language == 'en'
                        ? `This field is required to post your profile picture.`
                        : `Ce champ est obligatoire pour publier votre photos de profil.`
                })
                return
            }
            setError(null)

            setIsLoading(true)
            const form = new FormData()
            form.append('image', file)
            form.append('caption', description)
            form.append(
                'publish',
                publish
                    ? 1
                    : 0
            )
            const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/profile-image`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                }
            })
            const response = await request?.data
            //    console.log(response, 'response of insert cover of profil')
            setIsLoading(false)
            if (request.status === 200) {
                if (response.success) {
                    setPublish(false)
                    setDescription(null)
                    onRefresh(user?.user_id)
                    handleClose()
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error.message)
        }
    }

    return (
        <div className={styles.container}>

            <ProfilePicture
                user_id={user?.user_id}
                uri={user?.profile?.prof_picture}
                size={140}
            />

            {authReducerState?.user?.user_id === user?.user_id &&
                <button onClick={open} className={styles.button}>
                    <FaCamera color={color.dark} className={styles.icon} />
                </button>
            }

            <Dialog open={isVisible} onClose={handleClose}>
                <DialogTitle>{t('profile_picture')}</DialogTitle>
                <DialogContent style={{}}>
                    <div className={styles.containerImage}>
                        {file &&
                            <img src={fileUri || file?.uri}
                                alt='cover'
                                style={{
                                    width: '200px',
                                    height: '200px',
                                    border: '2px solid #808080',
                                    borderRadius: 200,
                                    objectFit: 'cover',
                                }}
                            />
                        }
                    </div>
                    <div className={styles.loading}>
                        {isLoading && <Spinner size='sm' />}
                    </div>

                    <TextField
                        defaultValue={''}
                        autoFocus
                        margin="dense"
                        accept="image/*"
                        type="file"
                        fullWidth
                        variant="outlined"
                        onChange={(event) => handleSelectFile(event)}
                        style={{
                            width: '90%',
                            margin: '15px'
                        }}
                    />

                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                        <span style={{ color: 'black', fontWeight: '500', fontSize: '14px', marginRight: '20px' }}>
                            {i18n.language == 'en'
                                ? 'Publish'
                                : 'Publier'
                            }
                        </span>
                        <Switch
                            checked={publish}
                            onChange={onTogglePublish}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={color.primary}
                            handleDiameter={20}
                            height={25}
                        />
                    </div>

                    <TextField
                        autoFocus
                        margin="dense"
                        label={i18n.language == 'en'
                            ? 'Say something about your profile pictures'
                            : 'Dites quelque chose à propos de votre photos de profile'
                        }
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        style={{
                            width: '90%',
                            margin: '15px'
                        }}
                    />
                    {error?.description &&
                        <p style={{ color: 'red', fontSize: 12, marginLeft: '15px' }}>
                            {error?.description}
                        </p>
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={HandleUpdateProfilPicture}>{t('save')}</Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default memo(CoverProfilePicture)