import React from 'react'
import Header from '../../../components/Header/Header'
import styles from './EditProfile.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useEffect } from 'react'
import { api } from '../../../api/api'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import useReducerAuthAction from '../../../hooks/ReducerHooksAction/useReducerAuthAction'
import { Spinner } from 'react-bootstrap'
import { Alert, Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import AutoCompleteSupervisor from 'components/AutoCompleteSupervisor/AutoCompleteSupervisor'
import SelectMultiPageCat from 'components/SelectMultiPageCat/SelectMultiPageCat'


const EditProfile = () => {

    const { t, i18n } = useTranslation()
    const [isEnLang, setIsEnLang] = useState(i18n.language === 'en')
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const { data: authReducerState } = useAuthReducer()

    const [user, setUser] = useState(authReducerState?.user)

    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()

    const controller = new AbortController();
    const signal = controller.signal;

    // COUNTRIES STATE
    const [countries, setCountries] = useState([])
    const [countriesIP, setCountriesIP] = useState([])


    // USER STATE
    const [profileTitle, setProfileTitle] = useState(authReducerState?.profile?.prof_title)
    const [profileDescription, setProfileDescription] = useState(authReducerState?.profile?.prof_description)
    const [profileEmail, setProfileEmail] = useState(authReducerState?.profile?.prof_email)
    const [profileBirthday, setProfileBirthday] = useState(authReducerState?.profile?.prof_birthday)
    const [profileWebsite, setProfileWebsite] = useState(authReducerState?.profile?.prof_site_web)
    const [profilePhone, setProfilePhone] = useState(authReducerState?.profile?.prof_phone)
    const [profilePhoneIndic, setProfilePhoneIndic] = useState(authReducerState?.profile?.prof_phone_indic)

    const [name, setName] = useState(authReducerState?.user?.user_name)
    const [surname, setSurname] = useState(authReducerState?.user?.user_surname)
    const [email, setEmail] = useState(authReducerState?.user?.user_email)
    const [username, setUsername] = useState(authReducerState?.user?.user_username)
    const [civility, setCivility] = useState(authReducerState?.user?.user_civility)
    const [city, setCity] = useState(authReducerState?.user?.user_ville)
    const [country, setCountry] = useState('')

    // STATE RH
    const [userSupID, setUserSupID] = useState(authReducerState?.user?.user_sup_id)
    const [userSupervisor, setUserSupervisor] = useState({
        user_id: authReducerState?.user?.upper_hierarchy?.user_id,
        user_name: authReducerState?.user?.upper_hierarchy?.user_name,
        user_surname: authReducerState?.user?.upper_hierarchy?.user_surname,
    })
    const [userEmployer, setUserEmployer] = useState(authReducerState?.user?.user_employer)
    const [userDirection, setUserDirection] = useState(authReducerState?.user?.user_direction)
    const [userDepartment, setUserDepartment] = useState(authReducerState?.user?.user_department)
    const [userService, setUserService] = useState(authReducerState?.user?.user_service)

    const [employers, setEmployers] = useState([]);

    const [user_visible, setUser_visible] = useState(true)
    const [categories, setCategories] = useState([])


    // REQUEST STATE
    const [errors, setErrors] = useState({})
    const [isFetching, setIsFetching] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)



    // DROPDOWN STATE
    const [employersOptions, setEmployersOptions] = useState([])
    const [directionOptions, setDirectionOptions] = useState([])
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])


    // NAVIGATION HANDLE 
    const goBack = () => navigate(-1)


    const genders = [
        {
            value: 'M',
            label: 'Male',
        },
        {
            value: 'F',
            label: 'Female',
        },
        {
            value: 'A',
            label: 'Autre',
        },
    ]

    const visibilityOptions = [
        { label: 'Visible', value: true },
        { label: 'Invisible', value: false }
    ]



    useEffect(() => {
        const fetchEntity = async () => {
            try {
                const request = await api(`api/rh/employers`, 'GET', {}, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Fetch employees response:', response)
                if (request?.ok && request?.status === 200) {
                    setEmployersOptions(response)
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchEntity()
    }, [])

    useEffect(() => {
        const fetchDirection = async () => {
            try {
                const request = await api(`api/rh/directions`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch employees response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setDirectionOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchDirection()
    }, [])

    useEffect(() => {
        const fetchDepartment = async () => {
            try {
                const request = await api(`api/rh/departments`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch departments response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setDepartmentOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchDepartment()
    }, [])

    useEffect(() => {
        const fetchService = async () => {
            try {
                const request = await api(`api/rh/services`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch services response:', response)
                if (request?.ok && request?.status === 200) {
                    const formattedOptions = response.map((item) => ({
                        value: item.value,
                        label: item.value,
                    }));
                    setServiceOptions(formattedOptions);
                }
            } catch (error) {
                console.log('Erreur', error.message)
            }
        }
        fetchService()
    }, [])


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const request = await api('api/get-countries', 'GET', null, signal)
                const response = await request.json()
                // console.log('Fetch countries:', response)
                if (request.ok && request.status === 200) {
                    const countries = response.countries.map(c => ({
                        label: c.French_Name,
                        value: c.Sort_Order,
                        key: c.ITU_T_Telephone_Code,
                    }))
                    setCountries(countries)
                }
            } catch (error) {
                fetchCountries()
                console.log('Erreur', error.message)
            }
        }
        fetchCountries()
        fetchUserAccount()
        getIPCountries()
        return () => {
            controller.abort()
        }
    }, [])

    const getIPCountries = async () => {
        try {
            const request = await api('api/ip-countries', 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Fetch ip contries:', response)
            if (request.ok && request.status === 200) {
                const indicatCountries = response?.filter(countrie =>
                    countrie?.ip_country_indic !== null
                ).map(countrie => ({
                    id: countrie?.ip_country_id,
                    label: `(${countrie?.ip_country_indic}) ${countrie?.ip_country_name}`,
                    value: countrie?.ip_country_indic,
                    flag: countrie?.ip_country_flag
                })).sort((a, b) => a?.value?.localeCompare(b?.value))
                setCountriesIP(indicatCountries)
            }
        } catch (error) {
            getIPCountries()
            Alert.alert('Erreur', error.message)
        }
    }



    const fetchUserAccount = async () => {
        try {
            const request = await api('api/user/edit-profile', 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Edit profile response:', response)
            setIsFetching(false)
            if (request?.ok && request?.status === 200) {
                if (response?.success) {

                    setProfileTitle(response?.data?.profile?.prof_title)
                    setProfileDescription(response?.data?.profile?.prof_description)
                    setProfileEmail(response?.data?.profile?.prof_email)
                    setProfileWebsite(response?.data?.profile?.prof_site_web)
                    setProfilePhone(response?.data?.profile?.prof_phone)
                    setProfilePhoneIndic(response?.data?.profile?.prof_phone_indic)
                    setProfileBirthday(response?.data?.profile?.prof_birthday)

                    setSurname(response?.data?.user_surname || response?.data?.user_name)
                    setName(!response?.data?.user_surname ? '' : response?.data?.user_name)
                    setUsername(response?.data?.user_username)
                    setEmail(response?.data?.user_email)
                    setCivility(response?.data?.user_civility)
                    setCity(response?.data?.user_ville)
                    setCountry(response?.data?.user_pays?.toString())

                    setUser_visible(response?.data?.user_visible)
                    setCategories(response?.data?.categories || [])

                }
            }
        } catch (error) {
            fetchUserAccount()
            console.error(error);
        }
    }


    // CHECK FORM 
    // const checkForm = async (e) => {
    //     e.preventDefault();
    //     if (user_surname.trim().length < 3) {
    //         setErrors(t('your_surname_must_contain_at_least_4_characters'))
    //         return
    //     }
    //     if (!ValidatePseudo(user_username.trim())) {
    //         setErrors(t('your_nickname_must_contain_at_least_4_characters_only_underscore'))
    //         return
    //     }
    //     if (!ValidateEmail(user_email.trim())) {
    //         setErrors(t('invalid_email'))
    //         return
    //     }
    //     setError('')
    //     handleSubmit()
    // }


    const handleUpdateProfil = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true)
            const body = {
                prof_title: profileTitle,
                prof_description: profileDescription,
                prof_email: profileEmail,
                user_visible: user_visible,
                prof_site_web: profileWebsite,
                user_civility: civility,
                user_surname: surname,
                user_name: name,
                user_username: username,
                user_ville: city,
                prof_phone: profilePhone,
                prof_phone_indic: profilePhoneIndic,
                prof_birthday: profileBirthday,
                user_email: email,
                user_pays: parseInt(country),
                prof_country: parseInt(country),
                prof_city: parseInt(city)
            }

            const request = await api(`api/profiles/${authReducerState?.user?.user_id}`, 'PUT', body, accessToken)
            const response = await request.json()
            // console.log(response, 'response of profil edit ')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    updateWorkProfile()
                }
            } else {
                if (response?.error === 'The nickname is already taken') {
                    setErrors(t('the_nickname_is_already_taken'))
                } else {
                    setErrors('profilEdit:sorry')
                }
            }
        } catch (response) {
            setIsLoading(false)
            console.log(response);
        }
    }

    const updateWorkProfile = async () => {
        try {
            const body = {
                prof_title: profileTitle,
                user_sup_id: userSupervisor?.user_id,
                user_employer: userEmployer,
                user_direction: userDirection,
                user_department: userDepartment,
                user_service: userService,
            }
            const request = await api(`api/rh/update-profile`, 'PUT', body, accessToken)
            const response = await request.json()
            // console.log('Update profile response:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchUser()
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error);
        }
    }


    const fetchUser = async () => {
        try {
            const request = await api('api/get-user', 'GET', {}, { ...accessToken, ...signal })
            const response = await request.json()
            // console.log('Response fetch user:', response?.user)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
                goBack()
            } else if (request.status === 401 || request.status === 403) {
                LOGOUT()
            }
        } catch (e) {
            // fetchUser()
            console.warn('Error fetch user info:', e.message)
            throw new Error(e)
        }
    }



    if (isFetching) {
        return (
            <div className={styles.screen}>
                <Header title={t('edit_profile')} goBack={goBack} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner style={{ marginTop: '40vh' }} />
                </div>
            </div >
        )
    }


    return (
        <div className={styles.screen}>
            <Header title={t('edit_profile')} goBack={goBack} />
            <div className={styles.container}>


                {/* {error && 
                    <p className={styles.textError}>{error}</p>} */}


                <Box
                    component="form"
                    onSubmit={handleUpdateProfil}
                    sx={{
                        '& .MuiTextField-root': {
                            mx: 3,
                            my: 2,
                            width: { xs: '89%', md: '89%' }
                        },
                    }}
                    noValidate
                    autoComplete="off">

                    <div>

                        <TextField
                            label="Titre"
                            type='name'
                            value={profileTitle || ''}
                            onChange={(event) => setProfileTitle(event.target.value)}
                            error={errors?.title}
                            helperText={errors?.title}
                            size='small'
                        />

                        {authReducerState?.user?.user_type !== 'PAGE' &&
                            <div>
                                <AutoCompleteSupervisor
                                    defaultValue={`${userSupervisor?.user_surname || ''} ${userSupervisor?.user_name || ''}`}
                                    value={userSupervisor}
                                    onChangeText={setUserSupervisor}
                                />


                                <TextField
                                    select
                                    label={t('companyOrEntity')}
                                    defaultValue={employersOptions[0]?.value || ''}
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={userEmployer || ''}
                                    onChange={(event) => setUserEmployer(event.target.value)}>
                                    {employersOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.value}
                                        </option>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    label={t('division')}
                                    // defaultValue={''}
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={userDirection || ''}
                                    onChange={(event) => setUserDirection(event.target.value)}>
                                    {directionOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    label={t('departement')}
                                    // defaultValue={''}
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={userDepartment || ''}
                                    onChange={(event) => setUserDepartment(event.target.value)}>
                                    {departmentOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>


                                <TextField
                                    select
                                    label={t('unit')}
                                    // defaultValue={''}
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={userService || ''}
                                    onChange={(event) => setUserService(event.target.value)}>
                                    {serviceOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>
                            </div>
                        }


                        <TextField
                            required
                            label={t('descriptionOfThePage')}
                            multiline
                            maxRows={10}
                            value={profileDescription || ''}
                            onChange={(event) => setProfileDescription(event.target.value)}
                            error={errors?.description}
                            helperText={errors?.description}
                            size='small'
                        />


                        {authReducerState?.user?.user_type === 'PAGE' &&
                            <div>
                                <TextField
                                    select
                                    label={t('pageVisibility')}
                                    defaultValue={''}
                                    size='small'
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={user_visible || ''}
                                    onChange={(event) => setUser_visible(event.target.value)}>
                                    {visibilityOptions?.map((option) => (
                                        <option selected={option?.value === user_visible} key={option.value} value={option.value}>
                                            {option?.label}
                                        </option>
                                    ))}
                                </TextField>

                                <SelectMultiPageCat
                                    categories={categories}
                                    setCategories={setCategories}
                                />
                            </div>
                        }


                        <TextField
                            required
                            label={t('profile_email')}
                            type='email'
                            value={profileEmail || ''}
                            onChange={(event) => setProfileEmail(event.target.value)}
                            error={errors?.email}
                            helperText={errors?.email}
                            size='small'
                        />

                        <TextField
                            required
                            label={t('surname')}
                            type='name'
                            value={name || ''}
                            onChange={(event) => setName(event.target.value)}
                            size='small'
                        />

                        <TextField
                            required
                            label={t('name')}
                            type='name'
                            value={surname || ''}
                            onChange={(event) => setSurname(event.target.value)}
                            size='small'
                        />

                        <TextField
                            required
                            label={t('nickname')}
                            type='name'
                            value={username || ''}
                            onChange={(event) => setUsername(event.target.value?.toLowerCase()?.replaceAll(' ', '_')?.trim())}
                            size='small'
                        />

                        <TextField
                            select
                            label={t('civility')}
                            defaultValue=""
                            size='small'
                            SelectProps={{
                                native: true,
                            }}
                            value={civility || ''}
                            onChange={(event) => setCivility(event.target.value)}>
                            {genders?.map((option, index) => (
                                <option key={index.toString()} value={option.value}>
                                    {option?.label}
                                </option>
                            ))}
                        </TextField>

                        <TextField
                            label={t('city')}
                            value={city || ''}
                            onChange={(event) => setCity(event.target.value)}
                            size='small'
                        />

                        <TextField
                            select
                            label={t('country')}
                            defaultValue=""
                            size='small'
                            SelectProps={{
                                native: true,
                            }}
                            value={country || ''}
                            onChange={(event) => setCountry(event.target.value)}>
                            {countries?.sort((a, b) =>
                                a.label.localeCompare(b.label)
                            )?.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option?.label}
                                </option>
                            ))}
                        </TextField>

                        <div style={{ display: 'flex', }}>
                            <TextField
                                select
                                label={t('indicative')}
                                // defaultValue={''}
                                size='small'
                                SelectProps={{
                                    native: true,
                                }}
                                value={profilePhoneIndic || ''}
                                onChange={(event) => setProfilePhoneIndic(event.target.value)}
                                style={{ width: '34%' }}>
                                {countriesIP?.map((option, index) => (
                                    <option key={index.toString()} value={option.value}>
                                     {option?.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                label={t('phone_number')}
                                type='phone'
                                value={profilePhone || ''}
                                onChange={(event) => setProfilePhone(event.target.value)}
                                size='small'
                                style={{ width: '90%' }}
                            />
                        </div>


                        <TextField
                            label={t('web_site')}
                            value={profileWebsite || ''}
                            onChange={(event) => setProfileWebsite(event.target.value)}
                            size='small'
                        />

                        {errors?.request &&
                            <Alert severity="error" className={styles.textError}>{errors?.request}</Alert>
                        }

                        {errors?.success &&
                            <Alert severity="success" className={styles.textError}>{errors?.success}</Alert>
                        }

                        <div className={styles.buttonBox}>
                            <ButtonSubmit
                                value={t('update')}
                                isLoading={isLoading}
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 600,
                                    width: '50%',
                                    color: 'white',
                                    marginTop: '20px',
                                    marginBottom: '150px',
                                }}
                            />
                        </div>


                    </div>
                </Box>

            </div>
        </div>
    )
}

export default EditProfile