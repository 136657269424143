import React, { useEffect, useState } from 'react'
import styles from './RequestDetails.module.css'
import { useTranslation } from 'react-i18next'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../../../../components/Header/Header'
import { MdMailOutline } from 'react-icons/md';
import { color } from '../../../../hooks/Utils/color'
import { api } from '../../../../api/api'
import Spinner from 'react-bootstrap/Spinner'
import ButtonSubmit from '../../../../components/ButtonSubmit/ButtonSubmit'
import axios from 'axios'
import TextInput from '../../../../components/TextInput/TextInput'
import dayjs from 'dayjs'
import 'react-calendar/dist/Calendar.css';


const RequestDetails = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const [userLanguage, setUserLanguage] = useState(i18n.language)

    const { id } = useParams();
    const navigate = useNavigate()

    const controller = new AbortController();
    const signal = controller.signal;

    const { data: authReducerState } = useAuthReducer()
    const [user, setUser] = useState(authReducerState?.user)

    const [userRequest, setUserRequest] = useState({})
    const [request, setRequest] = useState({})
    const [document, setDocument] = useState({})
    const [type, setType] = useState({})

    const [numberOfDays, setNumberOfDays] = useState(0)

    const [selectedFile, setSelectedFile] = useState(null);

    const [isLoading, setIsLoading] = useState(true)
    const [isSubmit, setIsSubmit] = useState(false)
    const [isTreat, setIsTreat] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [isRejet, setIsRejet] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)

    const [status, setStatus] = useState('')
    const [statusColor, setStatusColor] = useState('#808080')

    // NAVIGATION HANDLE
    const goBack = () => navigate(-1)

    useEffect(() => {
        fetchRequest()
    }, [])

    const fetchRequest = async () => {
        try {
            setIsLoading(true)
            const request = await api(`api/rh/hr_requests/${id}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Fetch details request response:', response)
            setIsLoading(false)
            if (request?.status === 200) {
                if (response?.success) {
                    setRequest(response?.data)
                    setUserRequest(response?.data?.user)
                    setDocument(response?.data?.document)
                    setType(response?.data?.type)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Fetch request sent error:', error);
        }
    }

    useEffect(() => {
        if (request?.hr_req_status === 'DRAFT') {
            setStatus(t('draft'))
            setStatusColor('#808080')
            return
        }
        if (request?.hr_req_status === 'IN_PROGRESS') {
            setStatus(t('pending'))
            setStatusColor(color.warning)
            return
        }
        if (request?.hr_req_status === 'TREATED') {
            setStatus(t('treated'))
            setStatusColor(color.success)
            return
        }
        if (request?.hr_req_status === 'REJECTED') {
            setStatus(t('rejected'))
            setStatusColor(color.danger)
            return
        }
    }, [request?.hr_req_status])


    const handleSubmit = async () => {
        try {
            setIsSubmit(true)
            const request = await api(`api/rh/hr_requests/${id}/submit`, 'POST', {}, { ...accessToken, ...signal })
            const response = await request.json()
            console.log('Submit requests response:', response)
            setIsSubmit(false)
            if (request?.ok && request?.status === 200) {
                fetchRequest()
            }
        } catch (error) {
            setIsSubmit(false)
            console.error('Submit requests error:', error);
        }
    }


    const handleDelete = async () => {
        try {
            setIsDelete(true)
            const request = await api(`api/rh/hr_requests/${id}`, 'DELETE', {}, { ...accessToken, ...signal })
            const response = await request.json()
            console.log('Delete requests response:', response)
            setIsDelete(false)
            if (request?.ok && request?.status === 200) {
                fetchRequest()
            }
        } catch (error) {
            setIsDelete(false)
            console.error('Delete requests error:', error);
        }
    }


    const handleValidate = async () => {
        try {
            setIsValidate(true)
            const request = await api(`api/rh/hr_requests/${id}/validate`, 'POST', {}, { ...accessToken, ...signal })
            const response = await request.json()
            console.log('Validate requests response:', response)
            setIsValidate(false)
            if (request?.ok && request?.status === 200) {
                fetchRequest()
            }
        } catch (error) {
            setIsValidate(false)
            console.error('Validate requests error:', error);
        }
    }


    const handleDismiss = async () => {
        try {
            setIsRejet(true)
            const request = await api(`api/rh/hr_requests/${id}/reject`, 'POST', {}, { ...accessToken, ...signal })
            const response = await request.json()
            console.log('Reject requests response:', response)
            setIsRejet(false)
            if (request?.ok && request?.status === 200) {
                fetchRequest()
            }
        } catch (error) {
            setIsRejet(false)
            console.error('Validate requests error:', error);
        }
    }


    const handleTreatRequest = async () => {
        try {
            setIsLoading(true)
            const formData = new FormData();

            formData.append(`hr_req_response`, numberOfDays);

            if (!selectedFile) {
                formData.append(`attachment`, null);
            } else {
                formData.append(`attachment`, selectedFile);
                formData.append(`attachment_mime_type`, selectedFile?.mimeType);
                formData.append(`attachment_extension`, selectedFile?.extension);
            }

            const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/rh/hr_requests/${id}/treat`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    ...accessToken,
                    signal,
                },
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    console.log(Math.round((loaded / total) * 100), '% envoi...')
                },
            });
            const response = await request.data
            console.log('Fetch treat request response:', response)
            setIsLoading(false)
            if (request?.status === 200 && response?.success) {
                fetchRequest()
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Fetch treat request error:', error?.response?.data || error);
        }
    }


    const renderLoading = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50%' }}>
                <Spinner animation="border" variant="dark" />
            </div>
        )
    }


    const TreatRequest = () => {
        return (
            <div>
                {request?.type?.hr_req_type_id === 4 && request?.hr_req_status !== 'TREATED' ?
                    <div style={{ marginLeft: '40px', marginTop: '40px', marginLeft: 'auto', marginRight: 'auto' }} className={styles.inputContainer}>
                        <p className={styles.titleInput}>
                            {t('numberOfDays')}
                        </p>
                        <div>
                            <TextInput
                                placeholder={t('numberOfDays')}
                                type='number'
                                value={numberOfDays}
                                setValue={setNumberOfDays}
                                style={{
                                    width: '50%',
                                }}
                            />
                        </div>
                    </div>
                    :
                    null
                }

                {request?.type?.hr_req_type_id === 3 && request?.hr_req_status !== 'TREATED' ?
                    <div style={{ marginLeft: '20px', marginTop: '20px', marginRight: 'auto' }}>
                        <p className={styles.titleInput}>
                            {t('administrativeDocument')}
                        </p>
                        <input
                            type='file'
                            onChange={(event) => setSelectedFile(event.target.files[0])}
                            style={{
                                width: '50%',
                            }}

                        />
                    </div>
                    :
                    null
                }

                {request?.hr_req_upper_1_status !== 'VALIDATED' ||
                    request?.hr_req_upper_2_status !== 'VALIDATED' ||
                    request?.hr_req_hr_manager_status !== 'VALIDATED' ?
                    <div>
                        <ButtonSubmit
                            onClick={handleValidate}
                            value={t('validateRequest')}
                            isLoading={isValidate}
                            style={{
                                width: '200px',
                                color: 'white',
                                fontWeigth: '600',
                                marginTop: '30px',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                        <ButtonSubmit
                            onClick={handleDismiss}
                            value={t('rejectRequest')}
                            isLoading={isRejet}
                            style={{
                                width: '200px',
                                color: 'white',
                                fontWeigth: '600',
                                marginTop: '30px',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                    </div>
                    :
                    null
                }

                {request?.hr_req_upper_1_status === 'VALIDATED' &&
                    request?.hr_req_upper_2_status === 'VALIDATED' &&
                    request?.hr_req_hr_manager_status === 'VALIDATED' &&
                    request?.hr_req_status !== 'TREATED' ?

                    <ButtonSubmit
                        onClick={handleTreatRequest}
                        value={t('treatRequest')}
                        isLoading={isSubmit}
                        style={{
                            width: '200px',
                            color: 'white',
                            fontWeigth: '600',
                            marginTop: '30px',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    />
                    :
                    null
                }
            </div>
        )
    }


    return (
        <div className={styles.screen}>
            <Header title={t('requestDetails')} goBack={goBack} />
            {isLoading ?
                renderLoading()
                :
                <div className={styles.container}>

                    <div className={styles.detail}>
                        <MdMailOutline color={color.success} className={styles.icon} />
                        <div>
                            <h6 className={styles.title}>{type?.hr_req_type_name}</h6>
                            {document &&
                                <p className={styles.document}>
                                    {document?.hr_req_doc_name}
                                </p>
                            }
                            <p className={styles.description}>Status: <span style={{ color: statusColor }} className={styles.status}>{status}</span></p>
                        </div>
                    </div>

                    <div className={styles.detailContainer}>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('requestSentBy')}:</span>
                            <span className={styles.data}>{userRequest?.user_surname} {userRequest?.user_name}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('mailAddress')}:</span>
                            <span className={styles.data}>{userRequest?.user_email}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('requestType')}:</span>
                            <span className={styles.data}>{type?.hr_req_type_name}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('documentType')}:</span>
                            <span className={styles.data}>{document?.hr_req_doc_name}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('statusRequest')}:</span>
                            <span style={{ color: statusColor }} className={styles.data}>{status}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('companyOrEntity')}:</span>
                            <span className={styles.data}>{userRequest?.user_employer}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('department')}:</span>
                            <span className={styles.data}>{userRequest?.user_department}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('division')}:</span>
                            <span className={styles.data}>{userRequest?.user_direction}</span>
                        </div>

                        <div className={styles.box}>
                            <span className={styles.label}>{t('unit')}:</span>
                            <span className={styles.data}>{userRequest?.user_service}</span>
                        </div>

                        <hr></hr>

                        <div style={{marginBotton: '20px'}}>
                            <h6 className={styles.title} style={{color: color.primary}}>{type?.hr_req_type_name}</h6>
                            {document &&
                                <p className={styles.document}>
                                    {document?.hr_req_doc_name}
                                </p>
                            }
                        </div>

                        {request?.hr_req_departure_date &&
                            <div className={styles.box}>
                                <span className={styles.label}>{t('departureDate')}:</span>
                                <span className={styles.data}>{dayjs(request?.hr_req_departure_date).format('DD/MM/YYYY')} {request?.hr_req_departure_time}</span>
                            </div>
                        }

                        {request?.hr_req_return_date &&
                            <div className={styles.box}>
                                <span className={styles.label}>{t('returnDate')}:</span>
                                <span className={styles.data}>{dayjs(request?.hr_req_return_date).format('DD/MM/YYYY')} {request?.hr_req_return_time}</span>
                            </div>
                        }

                    </div>
                    {authReducerState?.user?.user_id === userRequest?.user_id && request?.hr_req_status === 'DRAFT' ?
                        <ButtonSubmit
                            onClick={handleDelete}
                            value={t('delete')}
                            isLoading={isDelete}
                            style={{
                                width: '200px',
                                color: 'white',
                                fontWeigth: '600',
                                marginTop: '30px',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                            }}
                        />
                        :
                        null
                    }
                    {authReducerState?.user?.user_id !== userRequest?.user_id &&
                        TreatRequest()
                    }
                </div>
            }
        </div >
    )
}

export default RequestDetails