import React, { useEffect, useState } from 'react'
import styles from './Muted.module.css'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaUserTimes } from 'react-icons/fa'
import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { color } from 'hooks/Utils/color'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from '@mui/material'
import UserCard from './UserCard/UserCard'
import { FaUserCircle } from 'react-icons/fa'


const Muted = ({ user_id }) => {

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authReducerState } = useAuthReducer()

    const controller = new AbortController();
    const signal = controller.signal;

    const [search, setSearch] = useState('')
    const [searchData, setSearchData] = useState([])

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isFetching, setIsFetching] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isEmpty, setIsEmpty] = useState(false)


    useEffect(() => {
        fetchData()
        return () => {
            controller.abort()
        }
    }, [])


    const fetchData = async () => {
        try {
            const request = await api(
                `api/user/get-muted-users`,
                'GET',
                null,
                accessToken,
                signal
            )
            const response = await request.json()
            console.log('Fetch muted response:', response)
            setIsFetching(false)
            if (request?.status === 200) {
                setIsEmpty(response?.muted_users?.length === 0)
                setData(response?.muted_users)
                setSearchData(response?.muted_users)
            }
        } catch (error) {
            setIsFetching(false)
            console.error('Fetch blocked error:', error);
        }
    }


    if (isEmpty) {
        return (
            <div style={{ paddingTop: '30vh', textAlign: 'center' }}>
                <FaUserCircle color='#808080' style={{ width: '50px', height: '50px' }} />
                <h6 style={{ color: '#808080', fontWeight: '500', marginTop: '10px' }}>
                    {t('no_data_available')}
                </h6>
            </div>
        )
    }


    return (
        <div id="scrollableDiv" className={styles.container}>
            <InfiniteScroll
                dataLength={data?.length}
                // next={loadMore}
                hasMore={hasMore}
                loader={<div style={{ textAlign: 'center', padding: '15px' }}><Spinner /></div>}
                scrollableTarget="scrollableDiv">
                {data?.map((item, index) => {
                    return (
                        <div key={index.toString()}>
                            <UserCard
                                user_id={user_id}
                                user={item}
                                onRefresh={fetchData}
                            />
                        </div>
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default Muted