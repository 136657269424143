
import React, { memo } from 'react'
import styles from './Header.module.css'
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleSharp, IoCloseSharp } from 'react-icons/io5';


const Header = ({
  title = 'Screen',
  type = 'screen',
  renderMenu = () => null,
  className,
  goBack
}) => {

  return (
    <div className={`${styles.container} ${className}`}>
      <div>
        {type === 'screen' ?
          <button onClick={goBack} className={styles.backButton} >
            <MdOutlineKeyboardBackspace
              size={32}
            />
          </button>
          :
          <button onClick={goBack} className={styles.backButton}>
            <IoCloseCircleSharp
              size={32}
            />
          </button>
        }
      </div>
      <div className={styles.titleContainer}>
        <h5 className={styles.title}>
          {title}
        </h5>
      </div>
      <div className={styles.menuContainer}>
        {renderMenu()}
      </div>
    </div>
  )
}

export default memo(Header)