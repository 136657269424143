import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNotificationReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import NotifItem from '../../components/Notifications/NotifItem/NotifItem';
import Header from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';


const Notifications = () => {

    const navigate = useNavigate()
    const { data: notifData } = useNotificationReducer()

    return (
        <div>
            <Header title='Notifications' goBack={()=>navigate(-1)}/>
            <div id='container' style={{ height: '85vh', overflowY: 'auto' }}>
                <InfiniteScroll
                    dataLength={notifData.notifications.length} //This is important field to render the next data
                    next={() => null}
                    hasMore={false}
                    scrollableTarget='container'>
                    {notifData?.notifications.sort((a, b) => (b?.notif_id > a?.notif_id) ? 1 : -1).map((notif) => (
                        <NotifItem
                            key={notif.notif_id.toString()}
                            notif={notif}
                        />
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default Notifications