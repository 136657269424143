import React from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileCard from 'components/ProfileCard/ProfileCard'

const OpinionCard = ({
    opinion
}) => {

    const navigate = useNavigate()
    const MAX_LENGTH = 200

    const goToOpinion = () => navigate(`/opinion/select/${opinion?.agora?.ago_id || 0}/${opinion?.sond_id}`)

    return (
        <div onClick={goToOpinion} style={{ cursor: 'pointer', borderBottom: '3px solid #F1F1F1' }}>
            <ProfileCard
                user={opinion?.user}
                pictureSize={60}
            />
            <p style={{ marginLeft: '15px', marginRight: '15px', paddingTop: '10px' }}>
                {`${opinion?.sond_caption.substring(0, MAX_LENGTH)}...`}
            </p>
        </div>
    )
}

export default OpinionCard