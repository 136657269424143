import React, { memo, useState } from 'react'
import './PeopleMatchCarousel.style.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { usePeopleMatchReducer } from '../../hooks/ReducerHooks/ReducerHooks';
import RenderPeople from './RenderPeople/RenderPeople';
import { color } from '../../hooks/Utils/color';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { isMobile } from 'react-device-detect';
import { FiChevronRight } from 'react-icons/fi';


const PeopleMatchCarousel = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const { data: PeopleMatchData } = usePeopleMatchReducer()
    const [showList, setShowList] = useState(true)

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            // breakpoint: { max: 464, min: 0 },
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };



    return (
        <div className='people-match'>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center' }}>

                <div style={{ display: 'flex', alignItems: 'center', width: '80%' }}>

                    <h3 className='title' style={{ fontSize: '14px', color: color.primary }}>
                        {isMobile ?
                            `${t('colleagues_who_match').substring(0, 20)}...`
                            :
                            t('colleagues_who_match')
                        }
                    </h3>

                    {showList ?
                        <button onClick={() => setShowList(!showList)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '35px' }} >
                            <RiArrowUpSLine color={color.primary} size={32} style={{ marginBottom: '6px' }} />
                        </button>
                        :
                        <button onClick={() => setShowList(!showList)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '35px' }} >
                            <RiArrowDownSLine color={color.primary} size={32} style={{ marginBottom: '6px' }} />
                        </button>
                    }

                </div>

                <div style={{ display: 'flex', alignItems: 'center', width: '100px' }}>
                    <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => navigate('/people-you-match')} >
                        <h3 className='title' style={{ width: '60px', fontSize: '14px', color: color.primary, cursor: 'pointer' }}>{t('view_all')}</h3>
                    </button>
                    <div style={{ width: '25px' }}>
                        <FiChevronRight color={color.primary} size={20} />
                    </div>
                </div>

            </div>

            {showList &&
                <Carousel responsive={responsive}>
                    {PeopleMatchData.people.map((people, index) => {
                        return (
                            <RenderPeople
                                key={index.toString()}
                                people={people}
                            />
                        )
                    })}
                </Carousel>
            }

        </div>
    )
}

export default memo(PeopleMatchCarousel)