import { apiUrl } from 'api/api'
import { AlertError } from 'components/AlertError/AlertError'
import Header from 'components/Header/Header'
import Topbar from 'components/Topbar/Topbar'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { firestoreDB } from 'services/firebase'
import { scheme } from 'constants/env'
import firestorekeys from 'constants/firestorekeys'
import { doc, setDoc } from 'firebase/firestore'
import { generateChatId } from 'hooks/Utils/Utils'
import messagingKeys from 'constants/messagingKeys'


const GroupCreate = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authContextState } = useAuthReducer()

    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(null);
    const [error, setError] = useState(null);

    const handleFileChange = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const uri = window.URL.createObjectURL(file)
            setImage({ file, uri })
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (!name) {
                setError(i18n.language === 'en'
                    ? 'Enter group name.'
                    : 'Entrez le nom du groupe.'
                )
                return
            }
            if (name?.length < 3) {
                setError(i18n.language === 'en'
                    ? 'The group name must contain at least 3 characters.'
                    : 'Le nom du groupe doit contenir au moins 3 caractères.'
                )
                return
            }
            setError(null)

            if (!isLoading) {
                setIsLoading(true)
                const formData = new FormData()
                formData.append('conv_name', name)
                formData.append('conv_descr', description)

                if (image) {
                    formData.append('conv_picture', image?.file)
                }
                
                const response = await axios.post(`${apiUrl}/api/conversations`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        ...accessToken,
                    },
                    onUploadProgress: (progressEvent) => {
                        let { loaded, total } = progressEvent;
                        let pourcentage = Math.round((loaded / total) * 100)
                    },
                });
                setIsLoading(false)
                console.log('Response create new group:', response?.data)
                if (response?.status === 200) {
                    if (response?.data?.success) {
                        const chat_id = generateChatId(messagingKeys.groupChat, response?.data?.data?.conv_id, authContextState?.user?.user_id)
                        saveGroupToFirestore(chat_id, response?.data?.data)
                    }
                } else {
                    setError(i18n.language === 'en'
                        ? 'We encountered an error, please try again.'
                        : 'Nous avons rencontré une erreur, veuillez réessayer.'
                    )
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error create group:', error);
            setError(i18n.language === 'en'
                ? 'We encountered an error, please try again.'
                : 'Nous avons rencontré une erreur, veuillez réessayer.'
            )
        }
    }



    const saveGroupToFirestore = async (chat_id, conversation) => {
        const user = {
            profile: {
                prof_picture: conversation?.conv_picture || null
            },
            prof_picture: conversation?.conv_picture || null,
            user_id: conversation?.conv_id || null,
            user_surname: removeFirstWord(conversation?.conv_name) || null,
            user_name: conversation?.conv_name.split(' ')[0] || null,
            user_username: conversation?.conv_username || conversation?.conv_name?.toLowerCase()?.replaceAll(' ', '') || null,
            user_civility: conversation?.conv_civility || null,
            user_verified: conversation?.conv_verified || null,
            user_gold: conversation?.conv_gold || null,
            user_badge_business: conversation?.conv_user_badge_business || null,
            user_badge_color: conversation?.conv_user_badge_color || null,
            user_badge_food: conversation?.conv_user_badge_food || null,
            user_badge_goal: conversation?.conv_user_badge_goal || null,
            user_badge_hobby: conversation?.conv_user_badge_hobby || null,
            user_match_value: conversation?.conv_user_match_value || null,
        }

        const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

        await setDoc(docRef, {
            chat_id: chat_id,
            conversation_id: conversation?.conv_id,
            conversation_type: messagingKeys.groupChat,
            users: [user],
            participants: [
                conversation?.creator?.user_id
            ],
            last_message: null,
            timestamp: new Date().getTime()
        }).then(() => {
            console.log('Group chat saved to firstore.');
        }).catch((error) => {
            console.error('Error save firstore group chat:', error);
        });


        // GO TO ADD MEMBERS
        navigate(`/group/${conversation?.conv_id}/invite`, {
            state: {
                chat_id: chat_id,
                conv_id: conversation?.conv_id,
                conv_name: conversation?.conv_name
            },
            replace: true
        })
    }

    function removeFirstWord(sentence) {
        let words = sentence.split(' ');
        words.shift(); // Retire le premier mot
        return words.join(' ');
    }


    return (
        <div className='h-screen bg-white overflow-auto '>
            <Header title={t('new_group')} goBack={() => navigate(-1)} className='ps-3' />


            <form onSubmit={handleSubmit} className='px-12'>

                {error &&
                    <AlertError message={error} />
                }

                <div className="col-span-full mt-6">

                    {image ?
                        <div className="relative rounded-md">
                            <img
                                src={image?.uri}
                                alt={''}
                                className="object-cover bg-white w-36 h-36 mx-auto rounded-full border border-gray-200/25"
                            />
                            <div className="flex justify-center mt-3">
                                <Link onClick={() => setImage(null)} className='flex items-center text-white px-3 py-2 rounded-full bg-red-500 hover:text-red-700 '>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 me-2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                    <span className='text-xs font-bold'>
                                        {t('delete')}
                                    </span>
                                </Link>
                            </div>
                        </div>
                        :
                        <div>
                            <div className="flex justify-center items-center w-36 h-36 mx-auto rounded-full border border-dashed border-gray-900/25 dark:border-gray-200/25">
                                <div className="text-center">
                                    <div className="lex text-sm leading-6 text-gray-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 mx-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                        </svg>

                                        <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer rounded-md font-semibold text-green-700 hover:text-orange-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-700 focus-within:ring-offset-2">
                                            <span className='text-xs'>{t('upload_a_file')}</span>
                                            <input id="file-upload"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                                name="file-upload" type="file"
                                                className="sr-only"
                                            />
                                        </label>
                                        <p className="pl-1 text-xs">{t('or_drag_and_drop')}</p>
                                    </div>
                                    {/* <p className="text-xs leading-5 text-gray-600">Image/video</p> */}
                                </div>
                            </div>

                        </div>
                    }
                    {/* <p className='text-center font-bold text-gray-700 mt-3'>
                        {t('group_image')}
                    </p> */}
                </div>

                <div className="mt-6">
                    <p className='font-bold text-gray-700 mb-1'>
                        {t('group_name')}
                    </p>
                    <input
                        type='text'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t('enter_the_group_name')}
                        className="w-full bg-transparent rounded-md border-none border p-2 ring-0 outline-none sm:text-sm"
                        required
                    />
                </div>

                <div className="mt-3">
                    <p className='font-bold text-gray-700 mb-1'>
                        {t('group_description')}
                    </p>
                    <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder={t('enter_the_group_description')}
                        className="w-full bg-transparent  rounded-md border-none border p-2 ring-0 outline-none sm:text-sm"
                        rows={3}
                    />
                </div>


                <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-green-700 px-6 py-2  mt-6 mx-auto ms-0 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 ">
                    {isLoading ?
                        <div role="status" className='mx-4'>
                            <svg aria-hidden="true" className="inline w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <span> {t('create_group')}</span>
                    }
                </button>
            </form>
        </div >
    )
}

export default GroupCreate