import React from 'react'
import styles from './EventCard.module.css'
import { BiCalendar } from 'react-icons/bi'
import { MdDateRange } from 'react-icons/md'
import { GrLocation } from 'react-icons/gr'
import { color } from 'hooks/Utils/color'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'

const EventCard = ({ event }) => {


    const { t } = useTranslation()
    const navigate = useNavigate()
    const MAX_LENGTH = 200
    const description = event?.event_descr?.length < MAX_LENGTH ?
        event?.event_descr : `${event?.event_descr?.substring(0, MAX_LENGTH)}...`

    const gotoDetails = () => navigate(`/intranet/events/${event?.event_id}/details`)


    function TextWithMentionsAndHashtags({ text }) {
        if (!text) {
            return
        }
        const parts = text.split(/@\[([\w\s]+)\]\((\d+)\)|#(\w+)/g);
        return (
            <>
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {text.match(/@\[([\w\s]+)\]\((\d+)\)/g)?.[index] && (
                            <Mention
                                username={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/@\[([\w\s]+)\]/)[1]}
                                user_id={text.match(/@\[([\w\s]+)\]\((\d+)\)/g)[index].match(/\((\d+)\)/)[1]}
                            />
                        )}
                        {text.match(/#(\w+)/g)?.[index] && (
                            <Hashtag
                                tag={text.match(/#(\w+)/g)[index].replace("#", "")}
                            />
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    }

    const Mention = ({ username, user_id }) => {
        return (
            <Link to={`/profile/${user_id}`} style={{ color: color.primary, textDecoration: 'none', fontWeight: '500' }}>
                @{username}{' '}
            </Link>
        );
    }

    const Hashtag = ({ tag }) => {
        return (
            <Link to={`/search/${tag}`} style={{ color: color.primary, textDecoration: 'none', fontWeight: '500' }}>
                #{tag}{' '}
            </Link>
        );
    }


    return (
        <div onClick={gotoDetails} className={styles.container}>
            <div className={styles.iconContainer}>
                <BiCalendar size={38} color='rgb(65, 214, 224)' className={styles.icon} />
            </div>
            <div className={styles.content}>
                <h3 className={styles.title}>
                    {event?.event_title}
                </h3>
                <p className={styles.description}>
                    <TextWithMentionsAndHashtags text={description} />
                </p>
                <p className={styles.date}>
                    <GrLocation /> {t('location_of_the_event')}: {event?.event_localisation}</p>
                <p className={styles.date}>
                    <MdDateRange /> {t('event_date')}: {moment(event?.event_date, 'yyyy-MM-ddThh:mm:ssZ').format('DD MMM YYYY')} {t('at')} {event?.event_time}{t('at')} {event?.event_time}</p>
            </div>
        </div>
    )
}

export default EventCard