import React, { useEffect, useState } from 'react'
import styles from './Search.module.css'
import { MdAddBox, MdOutlineKeyboardBackspace } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../api/api'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { IoSearchSharp } from 'react-icons/io5'
import { color } from '../../hooks/Utils/color'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import SearchPeople from './SearchPeople/SearchPeople'
import SearchOpinion from './SearchOpinion/SearchOpinion'
import SearchAgora from './SearchAgora/SearchAgora'
import SearchPage from './SearchPage/SearchPage'
import Header from 'components/Header/Header'


const Search = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authData } = useAuthReducer()

    // TAB STATE
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (newTabIndex) => {
        setTabIndex(newTabIndex);
    };


    // SEARCH STATE
    const [text, setText] = useState('')

    const [peopleCount, setPeopleCount] = useState(0)
    const [isPeopleLoading, setIsPeopleLoading] = useState(false)

    const [opinionCount, setOpinionCount] = useState(0)
    const [isOpinionLoading, setIsOpinionLoading] = useState(false)

    const [agoraCount, setAgoraCount] = useState(0)
    const [isAgoraLoading, setIsAgoraLoading] = useState(false)

    const [pageCount, setPageCount] = useState(0)
    const [isPageLoading, setIsPageLoading] = useState(false)


    // NAVIGATION HANDLE
    const goToSearchBadge = () => navigate(`/search-badge`)
    const goToSearchRH = () => navigate('/search-rh')
    const goToSearchCategory = () => navigate('/search-category')
    const goToProfile = (user_username) => navigate(`/profiles/user/${user_username}`)
    const goToViewOpinion = (ago_id, sond_id) => navigate(`/opinion/select/${ago_id || 0}/${sond_id}`)

    const handleSearch = (e) => {
        e.preventDefault();
        if (text?.length >= 3) {
            setIsPeopleLoading(true)
            setIsOpinionLoading(true)
            setIsAgoraLoading(true)
            setIsPageLoading(true)
            handleResultCount()
        }
    }


    const handleResultCount = async () => {
        try {
            const body = { search: text }
            const request = await api('api/search/search_counts', 'POST', body, accessToken)
            const response = await request?.json()
            console.log('Search count response:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setPeopleCount(response?.search_person)
                    setOpinionCount(response?.search_opinion)
                    setAgoraCount(response?.search_agora)
                    setPageCount(response?.search_page)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }



    return (
        <div className={styles.page}>
            <Header title={t('search')} goBack={() => navigate(-1)} />
            <form onSubmit={handleSearch}>
                <div className={styles.search}>
                    <input
                        placeholder={`${t('search')}...`}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        className={styles.searchInput}
                    />
                    <button
                        onClick={handleSearch}
                        className={styles.searchButton}>
                        {!isPeopleLoading ?
                            <IoSearchSharp
                                size={20}
                                color='white'
                            />
                            :
                            <Spinner
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                variant='light'
                            />}
                    </button>
                </div>
            </form>

            <div className={styles.searchMenu}>
                <button
                    onClick={goToSearchBadge}
                    style={{
                        maxWidth: '30%',
                        backgroundColor: color.primary,
                        borderRadius: '25px',
                        paddingBottom: '3px',
                    }}>
                    <span
                        style={{
                            fontSize: '12px',
                            color: 'white',
                            marginLeft: '5px',
                            marginRight: '5px',
                        }}>
                        {t('search_by_badge')}
                    </span>
                </button>
                <button onClick={goToSearchRH} style={{maxWidth: '30%', backgroundColor: color.primary, borderRadius: '25px', paddingBottom: '3px' }}>
                    <span style={{padding: '0px', fontSize: '12px', color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                        {t('hr_search')}
                    </span>
                </button>

                <button onClick={goToSearchCategory} style={{maxWidth: '30%', backgroundColor: color.primary, borderRadius: '25px', paddingBottom: '3px' }}>
                    <span style={{fontSize: '12px', color: 'white', marginLeft: '5px', marginRight: '5px' }}>
                        {t('search_by_category')}
                    </span>
                </button>
            </div>

            {/* TAB */}
            <div className={styles.tab}>
                <span onClick={() => handleTabChange(0)} className={tabIndex === 0 ? styles.tabLabelActive : styles.tabLabel}>
                    {peopleCount <= 1 ? t('person') : t('people')}
                    <span className={tabIndex === 0 ? styles.tabBadgeActive : styles.tabBadge}>
                        {peopleCount}
                    </span>
                </span>
                <span onClick={() => handleTabChange(1)} className={tabIndex === 1 ? styles.tabLabelActive : styles.tabLabel}>
                    {opinionCount <= 1 ? 'Opinion' : 'Opinions'}
                    <span className={tabIndex === 1 ? styles.tabBadgeActive : styles.tabBadge}>
                        {opinionCount}
                    </span>
                </span>
                <span onClick={() => handleTabChange(2)} className={tabIndex === 2 ? styles.tabLabelActive : styles.tabLabel}>
                    {agoraCount <= 1 ? t('group') : t('groups')}
                    <span className={tabIndex === 2 ? styles.tabBadgeActive : styles.tabBadge}>
                        {agoraCount}
                    </span>
                </span>
                <span onClick={() => handleTabChange(3)} className={tabIndex === 3 ? styles.tabLabelActive : styles.tabLabel}>
                    {pageCount <= 1 ? 'Page' : 'Pages'}
                    <span className={tabIndex === 3 ? styles.tabBadgeActive : styles.tabBadge}>
                        {pageCount}
                    </span>
                </span>
            </div>
            <div className={tabIndex === 0 ? styles.tabViewActive : styles.tabView}>
                <SearchPeople
                    value={text}
                    isLoading={isPeopleLoading}
                    setIsLoading={setIsPeopleLoading}
                />
            </div>
            <div className={tabIndex === 1 ? styles.tabViewActive : styles.tabView}>
                <SearchOpinion
                    value={text}
                    isLoading={isOpinionLoading}
                    setIsLoading={setIsOpinionLoading}
                />
            </div>
            <div className={tabIndex === 2 ? styles.tabViewActive : styles.tabView}>
                <SearchAgora
                    value={text}
                    isLoading={isAgoraLoading}
                    setIsLoading={setIsAgoraLoading}
                />
            </div>
            <div className={tabIndex === 3 ? styles.tabViewActive : styles.tabView}>
                <SearchPage
                    value={text}
                    isLoading={isPageLoading}
                    setIsLoading={setIsPageLoading}
                />
            </div>
        </div>
    )
}

export default Search