import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './ressources/en'
import fr from './ressources/fr'

const LANGUAGES = {
    en,
    fr
}

i18n.use(initReactI18next).init({
    resources: LANGUAGES,
    lng: localStorage.getItem('language') || 'fr',
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    }
});