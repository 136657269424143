import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import Member from './MemberCard/MemberCard'
import LoadingPage from '../../../components/LoadingPage/LoadingPage'
import { color } from '../../../hooks/Utils/color'
import { Box, Tab, Tabs } from '@mui/material'
import Members from './Members/Members'
import MembersPending from './MembersPending/MembersPending'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import axios from 'axios'

const AgoraMembers = () => {

    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()

    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const { ago_id } = useParams()
    const { state } = useLocation();
    const { ago_title, agm_user_role } = state
    const [members, setMembers] = useState([])
    const [memberSearch, setMemberSearch] = useState([])
    const [requestSearch, setRequestSearch] = useState([])
    const [requests, setRequests] = useState([])
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const [memberCount, setMemberCount] = useState(0)
    const [memberPendingCount, setMemberPendingCount] = useState(0)

    const [tabIndex, setTabIndex] = React.useState(0);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    useEffect(() => {
        const fetchAgoraMembers = async () => {
            try {
                const request = await api(`api/agoras/${ago_id}`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log(response)
                if (response.status === 200) {
                    if (response?.success) {
                        setMemberCount(response?.members?.length)
                        setMemberPendingCount(response?.pendingRequest.length)
                    }
                }
            } catch (error) {
                console.log('Fetch Agora member error:', error)
            }
        }
        fetchAgoraMembers()
    }, [])

    
    return (
        <div style={{ height: '91vh', backgroundColor: 'white' }}>
            <Header title={`${ago_title} - ${t('members')}`} goBack={() => navigate(-1)} />
            <div style={{ height: '91vh', overflowY: 'hidden', backgroundColor: 'white' }}>

                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabIndex}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="inherit"
                                variant="fullWidth"
                                aria-label="full width tabs example">
                                <Tab label={t('members')} value={0}  />
                                <Tab label={t('requests')} value={1} />
                            </Tabs>
                        </Box>
                        <TabPanel value={0}>
                            <Members
                                ago_id={ago_id}
                                agm_user_role={agm_user_role}
                            />
                        </TabPanel>
                        <TabPanel value={1}>
                            <MembersPending
                                ago_id={ago_id}
                                agm_user_role={agm_user_role}
                            />
                        </TabPanel >
                    </TabContext>
                </Box>

                {/* <TabPanel value="2" style={{ padding: '0px' }}>
                    <MembersPending />
                </TabPanel > */}

                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                    type='search'
                    placeholder={`${t('search')}...`}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='search-input'
                />
            </div> */}

                {/* <InfiniteScroll
                    dataLength={members.length + requests.length}
                    next={() => null}
                    hasMore={false}>
                    {requests.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary }}>
                            Demande de participation ({requests.length})
                        </h6>
                    }
                    {requests.map((member, index) => {
                        return (
                            <Member
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchAgoraMembers}
                            />
                        )
                    })}

                    {members.length !== 0 &&
                        <h6 style={{ fontWeight: 'bold', padding: '8px', color: color.white, backgroundColor: color.primary, marginTop: "20px" }}>
                            Liste des membres ({members.length})
                        </h6>
                    }
                    {members.map((member, index) => {
                        return (
                            <Member
                                key={index.toString()}
                                ago_id={ago_id}
                                member={member}
                                agm_user_role={agm_user_role}
                                agm_member_user_id={member?.pivot?.agm_user}
                                agm_member_role={member?.pivot?.agm_role}
                                agm_member_status={member?.pivot?.agm_status}
                                refresh={fetchAgoraMembers}
                            />
                        )
                    })}

                </InfiniteScroll> */}
            </div>

        </div>
    )
}

export default AgoraMembers