import React, { memo, useEffect, useState } from 'react'
import styles from './RenderRequest.module.css'
import { TbMailForward } from 'react-icons/tb';
import { color } from '../../../../../hooks/Utils/color';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const RenderRequest = ({
    request = {},
    document = {},
    type = {}
}) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const [status, setStatus] = useState('')
    const [statusColor, setStatusColor] = useState('#808080')

    const goToRequestDetails = () => navigate(`/intranet/request/${request?.hr_req_id}/details`)
    

    useEffect(() => {
        if (request?.hr_req_status === 'DRAFT') {
            setStatus(t('draft'))
            setStatusColor('#808080')
            return
        }
        if (request?.hr_req_status === 'IN_PROGRESS') {
            setStatus(t('pending'))
            setStatusColor(color.warning)
            return
        }
        if (request?.hr_req_status === 'TREATED') {
            setStatus(t('treated'))
            setStatusColor(color.success)
            return
        }
        if (request?.hr_req_status === 'REJECTED') {
            setStatus(t('rejected'))
            setStatusColor(color.danger)
            return
        }
    }, [])



    return (
        <div onClick={goToRequestDetails} className={styles.container}>
            <TbMailForward color={color.success} className={styles.icon} />
            <div>
                <h6 className={styles.title}>{type?.hr_req_type_name}</h6>
                {document &&
                    <p className={styles.document}>
                        {document?.hr_req_doc_name}
                    </p>
                }
                <p className={styles.description}>Status: <span style={{ color: statusColor }} className={styles.status}>{status}</span></p>
            </div>
        </div>
    )
}

export default memo(RenderRequest)