import Header from 'components/Header/Header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AIAssistant.module.css'
import { MdGTranslate, MdOutlineSms } from 'react-icons/md'
import { color } from 'hooks/Utils/color'
import { BsImage, BsVolumeUpFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'



const AIAssistant = () => {

  const { t } = useTranslation()
  const navigate = useNavigate()

  const goBack = () => navigate(-1)
  const goToCompletions = () => navigate("/intranet/ai-assistant/completions")
  const goToImageGeneration = () => navigate("/intranet/ai-assistant/image-generation")
  const goToSpeechToText = () => navigate("/intranet/ai-assistant/speech-to-text")
  const goToSpeechToTranslate = () => navigate("/intranet/ai-assistant/speech-to-translate")

  
  return (
    <div className={styles.page}>
      <Header title={t('myAIAssistant')} goBack={goBack} />
      <div className={styles.content}>

        <div onClick={goToCompletions} className={styles.option}>
          <div className={styles.optionIcon} style={{ backgroundColor: '#4b0082' }}>
            <MdOutlineSms className={styles.icon} />
          </div>
          <div>
            <h6 className={styles.optionLabel}>ChatGPT</h6>
            <p className={styles.optionDesc}>
              {t('textCompletionDesc')}
            </p>
          </div>
        </div>

        <div onClick={goToImageGeneration} className={styles.option}>
          <div className={styles.optionIcon} style={{ backgroundColor: color.warning }}>
            <BsImage className={styles.icon} />
          </div>
          <div>
            <h6 className={styles.optionLabel}>{t('imageGeneration')}</h6>
            <p className={styles.optionDesc}>
              {t('imageGenerationDesc')}
            </p>
          </div>
        </div>


        <div onClick={goToSpeechToText} className={styles.option}>
          <div className={styles.optionIcon} style={{ backgroundColor: color.danger }}>
            <BsVolumeUpFill className={styles.icon} />
          </div>
          <div>
            <h6 className={styles.optionLabel}>{t('speechToText')}</h6>
            <p className={styles.optionDesc}>
              {t('speechToTextDesc')}
            </p>
          </div>
        </div>


        <div onClick={goToSpeechToTranslate} className={styles.option}>
          <div className={styles.optionIcon} style={{ backgroundColor: color.success }}>
            <MdGTranslate className={styles.icon} />
          </div>
          <div>
            <h6 className={styles.optionLabel}>{t('speechToTranslateInEnglish')}</h6>
            <p className={styles.optionDesc}>
              {t('speechToTranslateInEnglishDesc')}
            </p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AIAssistant