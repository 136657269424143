import React, { useEffect, useState } from 'react'
import styles from './Pages.module.css'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { Spinner } from 'react-bootstrap'
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction'
import { useNavigate } from 'react-router-dom'
import PageCard from './PageCard/PageCard'

const Pages = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authReducerState, store: dispatch } = useAuthReducer()

    // REQUEST STATE
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { LOGIN, LOGOUT } = useReducerAuthAction()


    useEffect(() => {
        fetchData()
    }, [])


    const fetchData = async () => {
        try {
            setIsLoading(true)
            const request = await api(`api/my_pages?page=1`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Fetch pages response:', response.data)
            setIsLoading(false)
            if (request.status === 200) {
                if (response?.success) {
                    if (response?.data?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    setData(response?.data?.data)
                    loadMore()
                }
            }
        } catch (error) {
            console.error('Fetch pages error:', error?.message);
        }
    }

   
    const loadMore = async () => {
        try {
            if (hasMore && !isLoading) {
                setIsLoading(true)
                const request = await api(`api/my_pages?page=${2}`, 'GET', {}, accessToken)
                const response = await request.json()
                // console.log('Fetch pages response:', response.data.data[0])
                setIsLoading(false)
                if (request.status === 200) {
                    if (response.success) {
                        if (response?.data?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        setData([...data, ...response?.data?.data])
                    }
                }
            }
        } catch (error) {
            console.error('Fetch pages error:', error?.message);
        }
    }


    const handleChangeAccount = (account) => {
        dispatch({
            type: 'USER_CHANGE_ACCOUNT',
            payload: true
        })
        LOGOUT()
        setTimeout(() => {
            const data = {
                type: 'Bearer',
                access_token: account.access_token,
                user: account.page
            }
            LOGIN(data)
            navigate('/')
        }, 1000);
        setTimeout(() => {
            dispatch({
                type: 'USER_CHANGE_ACCOUNT',
                payload: false
            })
        }, 2000);
    }


    if (isLoading) {
        return (
            <Spinner size='sm' style={{ margin: '5vh' }} />
        )
    }

    return (
        <div className={styles.container}>
            {data?.map((account, index) => {
                return (
                    <div key={index.toString()}>
                        <PageCard account={account} />

                        {/* <img src={
                            account?.page?.profile?.prof_picture ?
                                account?.page?.profile?.prof_picture :
                                require('assets/images/background_opinion.jpg')}
                            className={styles.profilePicture}
                            alt=""
                        />
                        <h6 className={styles.name}>
                            {`${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}`?.length < 18 ?
                                `${account?.page?.user_surname || ''} ${account?.page?.user_name || ''}` :
                                `${account?.page?.user_surname || ''} ${account?.page?.user_name  || ''}`?.slice(0, 18) + '...'
                            }
                        </h6> */}
                    </div>
                )
            })}
        </div>
    )
}

export default Pages