import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Login.module.css';
import { useTranslation } from 'react-i18next';
import playstore from 'assets/images/playstore.png';
import appstore from 'assets/images/appstore.png';
import appgallery from 'assets/images/appgallery.png';
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit';
import TextInput from 'components/TextInput/TextInput';
import { ValidateEmail } from 'hooks/Utils/parsing';
import { color } from 'hooks/Utils/color';
import { Alert } from '@mui/material';
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction';
import { api } from 'api/api';
import i18next from 'i18next';


const Login = () => {

  const APP_VERSION = process.env.REACT_APP_VERSION
  const PLAYSTORE_URL = process.env.REACT_APP_PLAYSTORE_URL
  const APPSTORE_URL = process.env.REACT_APP_APPSTORE_URL

  // STATES
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { LOGIN, UPDATE_LANGUAGE } = useReducerAuthAction()

  const [isLoading, setIsLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleloading] = useState(false)
  const [isFacebookLoading, setIsFacebookLoading] = useState(false)
  const [isAppleLoading, setIsAppleLoading] = useState(false)

  const [showAlert, setShowAlert] = useState(false)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [IPAddress, setIPAddress] = useState('192.168.1.1');

  const language = localStorage.getItem('language')
  const languages = [
    {
      label: t('french_france'),
      value: 'fr'
    },
    {
      label: t('english_us'),
      value: 'en'
    },
  ]



  /* CHANGE LANGUAGE */
  const handleChangeLanguage = (value) => {
    i18next.changeLanguage(value)
    UPDATE_LANGUAGE(value)
  }


  /* CHECK FORM */
  const checkForm = async (e) => {
    e.preventDefault();
    if (!ValidateEmail(email.trim())) {
      setError(t('invalid_email'))
      return
    }
    if (password.length < 8) {
      setError(t('invalid_password'))
      return
    }
    setError('')
    handleSubmit()
  }


  /* GET IP ADDRESS */
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get('https://geolocation-db.com/json/')
        // console.log(response.data)
        if (response.status === 200) {
          setIPAddress(response.data.IPv4)
        }
      } catch (e) {
        console.warn(e);
      }
    }
    fetchIPAddress()
  }, [])



  /* LOGIN HANDLER */
  const handleSubmit = async (e) => {
    setIsLoading(true)
    try {
      const body = {
        user_email: email,
        user_password: password,
        user_ip: IPAddress,
      }
      const request = await api('api/login', 'POST', body)
      const response = await request.json()
      // console.log(response)
      if (request.ok && request.status === 200) {
        LOGIN(response)
        setError('')
      } else {
        setError(t('incorrect_email_address_or_password'))
      }
      setIsLoading(false)
    } catch (error) {
      setError(t('an_error_occurred_please_try_again_later'));
      setIsLoading(false)
      console.warn(error.message);
    }
  };



  return (
    <div className={styles.container}>

      {/* DOWNLOAD CONTAINER */}
      <div className={styles.downloadContainer}>
        <span className={styles.downloadTitle}>
          {t('download_app')} {t('app_name')}
        </span>
        <span className={styles.downloadMobileTitle}>
          {t('download_app')}<br></br>{t('app_name')}
        </span>
        <div className='flex items-center'>
          <a href={PLAYSTORE_URL}>
            <img src={playstore} alt='' style={{ width: "75px" }} />
          </a>
          <a href={APPSTORE_URL}>
            <img src={appstore} alt='' style={{ width: "75px" }} />
          </a>
          <a href={PLAYSTORE_URL}>
            <img src={appgallery} alt='' style={{ width: "75px" }} />
          </a>
        </div>
      </div>
      {/* #END DOWNLOAD CONTAINER */}


      {/* LOGIN CONTAINER */}
      <div className={styles.loginContainer}>

        <div className={styles.loginLogo}>
          <img src={require('assets/app/icon_socialhub.png')}
            width={120}
            alt=''
          />
        </div>

        <h4 className={styles.loginDescrition} style={{ textAlign: 'justify', fontWeight: '400', fontSize: '14px', color: '#222', marginBottom: '20px' }}>
          {t('with_myopinion_share_and_stay')}
        </h4>

        <h3 className={styles.loginTitle}>
          {t('login')}
        </h3>


        <div className={styles.form}>
          <form onSubmit={checkForm} >
            {error &&
              <Alert severity="error"
                style={{
                  marginBottom: '20px'
                }}>
                {error}
              </Alert>
            }
            <TextInput
              value={email}
              setValue={setEmail}
              type='text'
              placeholder={t('email')}
              style={{
                width: '100%',
                marginBottom: '5%'
              }}
            />
            <TextInput
              value={password}
              setValue={setPassword}
              type='password'
              placeholder={t('password')}
              style={{
                width: '100%',
                marginBottom: '8%'
              }}
            />

            <ButtonSubmit
              value={t('login')}
              isLoading={isLoading}
              loadBoostrapColor={'light'}
              style={{
                width: '100%',
                color: 'white',
                marginBottom: '5%',
                backgroundColor: color.primary
              }}
            />
          </form>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to={'/forget-password'}
              className={styles.forgetPasswordText}>
              {t('forgot_your_password')}
            </Link>
          </div>

          <p className={styles.connectWith}>
            - {t('or')} -
          </p>

          <ButtonSubmit
            onClick={() => navigate('/register')}
            value={t('create_an_account')}
            loadBoostrapColor={'light'}
            style={{
              width: '100%',
              color: 'white',
              marginBottom: '30px',
              backgroundColor: color.primary
            }}
          />

          <Select
            placeholder='Langue'
            options={languages}
            isSearchable={false}
            defaultValue={languages.filter(items => items.value === language)}
            onChange={newValue => handleChangeLanguage(newValue.value)}
            className={styles.selectLanguageInput}
          />
        </div>

        <div className={styles.termsContainer}>
          {language === 'fr' ?
            <p className={styles.termsText}>
              En me connectant je confirme avoir lu et acceptez
              <Link to={'/legal/fr/conditions-generales'}> les conditions générales</Link>
              {/* et <Link to={'/legal/fr/conditions-generales'}> la politique de confidentialité</Link> */}
              .
            </p>
            :
            <p className={styles.termsText}>
              By logging in I confirm that I have read and accept
              <Link to={'/legal/fr/conditions-generales'}>the general conditions</Link>
              {/* and
              <Link to={'/legal/fr/conditions-generales'}> conditions and the privacy policy</Link> */}
              .
            </p>
          }

          <Link to={'/support'}>
            <p style={{ fontSize: 14, textAlign: 'center' }}>
              {language === 'fr'
                ? `Page d\'aide ${t('app_name')} (Support)`
                : `${t('app_name')} help page (Support)`
              }
            </p>
          </Link>
        </div>

        <p className={styles.copyrigthText}>
          {t('app_name')} © version {APP_VERSION} - {new Date().getFullYear()}
        </p>

        <div className={styles.footerLogo}>
          <img src={require('assets/app/icon.png')}
            width={50}
            alt=''
          />
        </div>

        <p className={styles.copyrigthText} style={{ fontSize: '12px' }}>
          Ministère de la Transition Numérique et de la Digitalisation
        </p>

      </div>
      {/* #END LOGIN CONTAINER */}

    </div >
  )
}

export default Login