import React from 'react'
import ProfileCard from '../../../../components/ProfileCard/ProfileCard'
import { useAccessToken, useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { BiUserX } from 'react-icons/bi'
import { color } from '../../../../hooks/Utils/color'
import { TbPlugConnected } from 'react-icons/tb'
import { AiOutlineDisconnect } from 'react-icons/ai'
import { api } from '../../../../api/api'
import { MdOutlineManageAccounts, MdPermIdentity } from 'react-icons/md'


const MemberCard = ({
    ago_id,
    member,
    agm_user_role,
    agm_member_user_id,
    agm_member_role,
    agm_member_status,
    onRefresh = () => null,
    onRemove = () => null,
}) => {

    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()


    const approveRequest = async () => {
        try {
            onRemove(agm_member_user_id)
            const body = {
                agm_status: 1,
                agm_user: agm_member_user_id,
                agm_agora: ago_id
            }
            const request = await api('api/agoras/moderate-request', 'POST', body, accessToken)
            const response = await request.json()
            console.log(response, 'response of approve request to join an agora ')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onRefresh()
                    // sendNotification(agm_member_user_id, {
                    //     title: 'Demande de participation approuvée',
                    //     body: `${t('membersListAgora:approveNtificationMessageHeader')} ${ago_title} ${t('membersListAgora:approveNtificationMessageFooter')}`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    const removeUser = async () => {
        try {
            onRemove(agm_member_user_id)
            const body = {
                agm_agora: ago_id,
                agm_user: agm_member_user_id,
            }
            const request = await api('api/agoras/members/remove', 'POST', body, accessToken)
            const response = await request.json()
            console.log(response['success'], 'Response remove user')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onRefresh()
                    // sendNotification(agm_member_user_id, {
                    //     title: `${t('membersListAgora:titleNotificationRemove')} ${ago_title}`,
                    //     body: `${t('membersListAgora:messageNotificationRemove')} ${ago_title}`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }




    const addUserAdmin = async () => {
        try {
            const body = {
                agm_agora: ago_id,
                agm_user: agm_member_user_id,
                agm_role: 2,
            }
            const request = await api('api/agoras/' + ago_id + '/give_privilege', 'POST', body, accessToken)
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onRefresh()
                    // sendNotification(agm_member_user_id, {
                    //     title: `${t('membersListAgora:titleNotificationAdmin')}`,
                    //     body: `${t('membersListAgora:messageNotificationAdmin')} ${ago_title}`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    // RETIRER UN ADMINISTRATEUR
    const RemoveUserAdmin = async () => {
        try {
            const body = {
                agm_agora: ago_id,
                agm_user: agm_member_user_id,
                agm_role: 0,

            }
            const request = await api('api/agoras/' + ago_id + '/give_privilege', 'POST', body, accessToken)
            const response = await request.json()
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onRefresh()
                    // sendNotification(agm_member_user_id, {
                    //     title: `${t('membersListAgora:titleNotificationMember')}`,
                    //     body: `${t('membersListAgora:messageNotificationMember')} ${ago_title}`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }



    const leaveAgora = async () => {
        try {
            const body = { agm_agora: ago_id }
            const request = await api('api/agoras/members/leave-agora', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response leave Agora:',)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    navigate('/agora', { replace: true })
                }
            }
        } catch (error) {
            console.error('Error leave user:', error)
        }
    }



    const renderButton = () => {

        // BUTTON DEMANDE DE PARTICIPATION
        if (agm_user_role === 2 && agm_member_status === 0) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <button
                        onClick={approveRequest}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '50%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <TbPlugConnected size={18} color={color.white} />
                        </div>
                        {t('approve')}
                    </button>

                    <button
                        onClick={removeUser}
                        className='button'
                        style={{
                            width: '50%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '500',
                            border: `1.8px ${color.primary} solid`
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <BiUserX size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                </div>
            )
        }


        // BUTTON QUITER L'AGORA
        if (authData.user.user_id === agm_member_user_id) {
            return (

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <button
                        onClick={leaveAgora}
                        className='button'
                        style={{
                            width: '49%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '500',
                            border: `1.8px ${color.primary} solid`,
                            marginRight: '-15px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <AiOutlineDisconnect size={18} color={color.primary} />
                        </div>
                        {t('leave')}
                    </button>
                </div>
            )
        }


        // BUTTONS PARTICIPANT EST ADMINISTRATEUR
        if (agm_user_role === 2 && agm_member_role == 2) {
            return (

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <button
                        onClick={RemoveUserAdmin}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '50%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <MdOutlineManageAccounts size={18} color={color.white} />
                        </div>
                        Admin
                    </button>

                    <button
                        onClick={removeUser}
                        className='button'
                        style={{
                            width: '50%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '500',
                            border: `1.8px ${color.primary} solid`
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <BiUserX size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                </div>

            )
        }


        // BUTTONS PARTICIPANT N'EST PAS ADMINISTRATEUR
        if (agm_user_role === 2 && agm_member_role == 1 || agm_user_role === 2 && agm_member_role == 0) {
            return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                    <button
                        onClick={addUserAdmin}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '50%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <MdPermIdentity size={18} color={color.white} />
                        </div>
                        {t('member')}
                    </button>

                    <button
                        onClick={removeUser}
                        className='button'
                        style={{
                            width: '50%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '500',
                            border: `1.8px ${color.primary} solid`
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <BiUserX size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                </div>

            )
        }

    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
                <ProfileCard
                    user={member}
                    pictureSize={50}
                    sourceFrom='AgoraMembers'
                />
            </div>
            <div style={{ width: '50%', paddingRight: '20px' }}>
                {renderButton()}
            </div>
        </div>
    )
}

export default MemberCard