import { api } from 'api/api'
import Header from 'components/Header/Header'
import messagingKeys from 'constants/messagingKeys'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { generateChatId } from 'hooks/Utils/Utils'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const Contact = () => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const { data: authReducerStore } = useAuthReducer()


    const [contacts, setContacts] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchBuddies = async () => {
            try {
                setIsLoading(true)
                const request = await api('api/user/get-buddies', 'GET', null, accessToken)
                const response = await request.json()
                setIsLoading(false)
                console.log('response buddies:', response?.buddies)
                if (request.ok && request.status === 200) {
                    const buddiesToStore = response?.buddies?.map(buddy => ({
                        user_id: buddy?.user_id,
                        name: buddy?.user_username,
                        profile: { prof_picture: buddy?.prof_picture },
                        user_name: buddy?.user_name,
                        user_surname: buddy?.user_surname,
                        user_username: buddy?.user_username,
                        user_civility: buddy?.user_civility,
                        user_verified: buddy?.user_verified,
                        user_match_value: buddy?.user_match_value,
                        user_badge_business: buddy?.user_badge_business,
                        user_badge_color: buddy?.user_badge_color,
                        user_badge_food: buddy?.user_badge_food,
                        user_badge_goal: buddy?.user_badge_goal,
                        user_badge_hobby: buddy?.user_badge_hobby,
                    }))
                    setContacts(buddiesToStore)
                }
            } catch (error) {
                console.error('Error get-buddies:', error)
                setTimeout(() => {
                    fetchBuddies()
                }, 2000);
            }
        }
        fetchBuddies()
    }, [])




    const goToOneToOneMessage = (user) => {
        navigate(`/one-to-one/${user?.user_id}`, {
            state: {
                chat_id: generateChatId('OneToOneChat', user?.user_id, authReducerStore?.user?.user_id),
                conv_id: user?.user_id,
                conv_type: messagingKeys.oneToOneChat,
                user,
                conversation: {
                    conv_name: user?.name,
                    conv_username: user?.user_username,
                    conv_picture: user?.prof_picture,

                    conv_type: messagingKeys.oneToOneChat,
                    conv_unread_messages_count: 0,

                    conv_user_badge_business: user?.user_badge_business,
                    conv_user_badge_color: user?.user_badge_color,
                    conv_user_badge_food: user?.user_badge_food,
                    conv_user_badge_goal: user?.user_badge_goal,
                    conv_user_badge_hobby: user?.user_badge_hobby,
                    conv_user_match_value: user?.match_value
                }
            },
            replace: true
        })
    }


    if (isLoading) {
        return (
            <div className='h-screen bg-white'>
                <Header title='Contacts' className={'ps-3 pt-1'} goBack={() => navigate(-1)} />
                <div className='flex items-center justify-center h-full pb-32'>
                    <Spinner variant='secondary' />
                </div>
            </div>
        )
    }

    return (
        <div className='h-screen bg-white'>
            <Header title='Contacts' className={'ps-3 pt-1'} goBack={() => navigate(-1)} />
            <div style={{ height: '93%' }} className='pb-20 lg:pb-0 overflow-y-scroll overflow-x-hidden'>
                {contacts.map(user => {
                    return (
                        <div onClick={() => goToOneToOneMessage(user)} className={'hover:bg-gray-100 cursor-pointer'}>
                            <div className={'flex items-center p-3'}>
                                <img src={user?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} alt='' className='w-12 h-12 me-3 rounded-full object-cover object-center' />
                                <div>
                                    <p>{user?.user_name} {user?.user_surname}</p>
                                    <p className='text-sm text-gray-400'>@{user?.user_name?.replace(' ', '')}{user?.user_surname?.replace(' ', '')}</p>
                                </div>
                            </div>
                            <div style={{ height: 1 }} className=' bg-gray-200 w-full ms-20' />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Contact