import React, { useCallback, useEffect, useState } from 'react'
import styles from './OneToOneCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { color } from 'hooks/Utils/color'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { collection, doc, onSnapshot, query, where } from 'firebase/firestore'
import firestorekeys from 'constants/firestorekeys'
import { scheme } from 'constants/env'
import { firestoreDB } from 'services/firebase'
import messagingKeys from 'constants/messagingKeys'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'


const OneToOneCard = ({
  conversation
}) => {

  const navigate = useNavigate()
  const { data: authreducerState } = useAuthReducer()

  const [messages, setMessages] = useState([])

  const goToConversation = () => {
    navigate(
      conversation?.conversation_type == messagingKeys.oneToOneChat
        ? `/one-to-one/${conversation?.conversation_id}`
        : `/conversation/${conversation?.conversation_id}`
      , {
        state: {
          chat_id: conversation?.chat_id,
          conv_id: conversation?.conversation_id,
          conv_type: conversation?.conversation_type,
          user: conversation?.user,
          conversation
        }
      })
  }

  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.messaging,
        firestorekeys.conversations,
        conversation?.chat_id?.toString(),
        firestorekeys.messages
      ),
      where('status', '==', 'no')
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      const unreadMessages = data?.filter(item =>
        item?.user?._id != authreducerState?.user?.user_id
      )
      // console.log('messages:', data)
      setMessages(unreadMessages)
    });
    return () => {
      unsubscribe();
    }
  }, [conversation?.chat_id]);


  return (
    <div className={'hover:bg-gray-100 cursor-pointer'} onClick={goToConversation}>
      <div className={'flex items-center p-3'}>
        <img src={conversation?.user?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} alt='' className='w-12 h-12 me-3 rounded-full object-cover object-center' />
        <div>
          <p>{conversation?.user?.user_name} {conversation?.user?.user_surname}</p>
          <p className='text-xs pt-0 mt-0 text-gray-400'>@{conversation?.user?.user_username}</p>
          <p className='text-sm mt-1 text-gray-600'>{conversation?.last_message?.text}</p>
        </div>
        {messages?.length !== 0 &&
          <div className='flex justify-center items-center bg-red-600 w-6 h-6 rounded-full ms-auto text-white text-xs'>
            {messages?.length}
          </div>
        }
      </div>
      <div style={{ height: 1 }} className='bg-gray-200 w-full ms-20' />
    </div>
  )
}

export default OneToOneCard