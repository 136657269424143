import { api } from "api/api";

const fetchEvents = async (pageParam = 1, accessToken) => {
    try {
        const request = await api(
            `api/events?page=${pageParam}`,
            'GET',
            null,
            accessToken
        )
        const response = await request.json()
        console.log('Response fetch events:', response)
        if (request.status === 200) {
            if (response?.success) {
                return response
            } else {
                throw new Error('Error fetch events')
            }
        } else {
            throw new Error('Error fetch events')
        }
    } catch (error) {
        throw new Error(error)
    }
}


export { fetchEvents }