import React, { memo } from 'react'
import './OpinionShare.style.css'
import OpinionBody from '../Opinion/OpinionBody/OpinionBody'

const OpinionShare = ({
    opinion,
    viewOpinion,
    setViewOpinion,
    sourceFrom,
    updateOpinion,
}) => {

    return (
        <div className='opinion-share'>

            <div className='user-container'>
                <img src={opinion?.user?.profile.prof_picture} className='picture-profile' alt='' />
                <div className='name-container'>
                    <h1 className='name'>{opinion?.user?.user_surname} {opinion?.user?.user_name}</h1>
                    <p className='username'>@{opinion?.user?.user_username}</p>
                </div>
            </div>

            <OpinionBody
                opinion={opinion}
                viewOpinion={viewOpinion}
                setViewOpinion={setViewOpinion}
                sourceFrom={sourceFrom}
                updateOpinion={updateOpinion}
            />

        </div>
    )
}

export default memo(OpinionShare)