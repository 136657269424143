import React, { useEffect, useState } from 'react'
import styles from './Settings.module.css'
import { Modal, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import Header from 'components/Header/Header'
import TextInput from 'components/TextInput/TextInput'
import Select from 'react-select'
import { api } from 'api/api'
import { ValidateEmail, ValidatePseudo } from 'hooks/Utils/parsing'
import { useAccessToken, useAccountReducer, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import i18next from 'i18next'
import { color } from 'hooks/Utils/color'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BsFillBookFill, BsPatchCheck } from 'react-icons/bs'
import { RiBookLine } from 'react-icons/ri'
import { AiOutlineSecurityScan } from 'react-icons/ai'
import { BiLockAlt } from 'react-icons/bi'
import { IoWalletOutline } from 'react-icons/io5'
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import { IoMdAddCircle } from 'react-icons/io'
import ProfilePicture from 'components/ProfilePicture/ProfilePicture'
import ModelAlert from 'components/ModalAlert/ModelAlert'
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction'
import Accounts from '../Components/Accounts/Accounts'
import Pages from '../Components/Pages/Pages'
import { FiMail } from 'react-icons/fi'
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import { AiOutlineUserSwitch } from "react-icons/ai";


const Settings = () => {

    const { user_id, likes } = useParams()

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const { data: authData } = useAuthReducer()
    const language = authData?.language
    const { state: accountReducerState } = useAccountReducer()
    const { data: authStateReducer } = useAuthReducer()
    const { state, dispatch } = useAccountReducer()


    const [isLoading, setIsLoading] = useState(false)
    const { UPDATE_LANGUAGE, LOGIN, LOGOUT } = useReducerAuthAction()
    const [error, setError] = useState('')

    const [showAlert, setShowAlert] = useState('')

    const [userEmailNotification, setUserEmailNotification] = useState(0)

    useEffect(() => {
        dispatch({
            type: 'ADD_ACCOUNT',
            payload: { ...authData, owner: authData?.user?.user_id }
        })
    }, [])

    const languages = [
        {
            label: t('french_france'),
            value: 'fr'
        },
        {
            label: t('english_us'),
            value: 'en'
        },
    ]

    const suscriptions = [
        {
            value: 1,
            label: t('yes')
        },
        {
            value: 0,
            label: t('no'),
        },
    ]


    // NAVIGATION HANDLER
    const goBack = () => navigate(-1)
    const goToAddAccount = () => navigate(`/profiles/user/accounts/add`)
    const goToCreatePage = () => navigate(`/profiles/user/pages/create`)
    const goToRequestList = () => navigate(`/profiles/user/pages/${authData?.user?.user_id}/settings/moderation-requests`)
    const goToBookmark = () => navigate(`/bookmark`)


    /* CHANGE LANGUAGE */
    const handleChangeLanguage = (value) => {
        i18next.changeLanguage(value)
        UPDATE_LANGUAGE(value)
    }

    useEffect(() => {
        console.log('owner:', authStateReducer?.owner)
        fetchSetting()
    }, [])

    const fetchSetting = async () => {
        try {
            const request = await api(`api/user/get-user-settings/${user_id}`, 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Response user settings:', response)
            setIsLoading(false)
            if (request.status === 200) {
                if (response.success) {
                    setUserEmailNotification(response.user_settings.user_email_notification)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetch setting:', error);
        }
    }

    const updateSetting = async (body) => {
        try {
            const request = await api(`api/user/set-user-settings`, 'POST', body, accessToken)
            const response = await request.json()
            console.log('Res^ponse update settings:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchSetting()
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }

    const logoutAccount = () => {
        try {
            if (authData?.user?.user_oauth_provider === 'facebook') {
                FacebookLoginClient.logout((res) => {
                    console.log('logout completed.');
                });
            }
            navigate('/')
            LOGOUT()
        } catch (error) {
            console.error(error);
        }
    }

    
    const handleChangeAccount = () => {
        dispatch({
            type: 'USER_CHANGE_ACCOUNT',
            payload: true
        })
        LOGOUT()
        setTimeout(() => {
            const data = {
                type: 'Bearer',
                access_token: authStateReducer?.owner?.authorization?.replace('Bearer ', ''),
                user: authStateReducer?.user
            }
            LOGIN(data)
            navigate('/')
        }, 1000);
        setTimeout(() => {
            dispatch({
                type: 'USER_CHANGE_ACCOUNT',
                payload: false
            })
        }, 2000);
    }



    const renderLoading = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '170px' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '30px',
                            height: '30px'
                        }}
                    />
                </div>
            </div>
        )
    }


    return (
        <div className={styles.container}>
            <Header title={t('setting')} goBack={goBack} />
            <div className={styles.content}>

                <span style={{ textAlign: 'center', color: 'red', marginTop: '10px' }}>{error}</span>

                {isLoading ? renderLoading() :

                    <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '3%' }}>

                        {(authData?.user?.user_type == 'PAGE' && authStateReducer?.owner) &&

                            <div onClick={handleChangeAccount} className={styles.ownerContainer}>
                                <img src={
                                    authStateReducer?.owner?.user?.profile?.prof_picture ?
                                        authStateReducer?.owner?.user?.profile?.prof_picture :
                                        require('assets/images/background_opinion.jpg')
                                }
                                    width={50}
                                    height={50}
                                    style={{
                                        borderRadius: '50px',
                                        marginLeft: 8
                                    }}
                                    alt=""
                                />
                                <div className={styles.ownerNameBox}>
                                    <p className={styles.ownerName}>
                                        {authStateReducer?.owner?.user?.user_surname || ''} {authStateReducer?.owner?.user?.user_name || ''}
                                    </p>
                                    <p className={styles.ownerUsername}>
                                        @{authStateReducer?.owner?.user?.user_username}
                                    </p>
                                </div>

                                <AiOutlineUserSwitch
                                    size={30}
                                    color='#c0c0c0'
                                    style={{ marginLeft: 'auto', marginRight: 10 }}
                                />
                            </div>
                        }

                        {authData?.user?.user_type !== 'PAGE' &&
                            <>
                                <div className={styles.containerAccount}>
                                    <span className={styles.titleAccount}>
                                        {t('my_accounts')}
                                    </span>
                                    <div style={{ display: 'flex', }}>
                                        <button
                                            onClick={goToAddAccount}
                                            className={styles.addAccountButton}
                                            style={{ padding: '8px' }}>
                                            <IoMdAddCircle size={35} color={color.primary} />
                                        </button>
                                        <Accounts />
                                    </div>
                                </div>

                                <div className={styles.containerAccount}>
                                    <span className={styles.titleAccount}>
                                        {t('my_pages')}
                                    </span>
                                    <div style={{ display: 'flex', }}>
                                        <button
                                            onClick={goToCreatePage}
                                            className={styles.addAccountButton}>
                                            <IoMdAddCircle size={35} color={color.primary} />
                                        </button>
                                        <Pages />
                                    </div>
                                </div>

                                <div onClick={goToRequestList} className={styles.containerOption}>
                                    <FiMail color='#808080' className={styles.iconOption} />
                                    <div className={styles.textContainerOption}>
                                        <p className={styles.textOption}>
                                            {t('listOfModerationRequests')}
                                        </p>
                                        <p className={styles.subtextOption}>
                                            {t('listOfModerationRequestsDesc')}
                                        </p>
                                    </div>
                                </div>


                                <div onClick={goToBookmark} className={styles.containerOption}>
                                    <FaRegBookmark color='#808080' className={styles.iconOption} />
                                    <div className={styles.textContainerOption}>
                                        <p className={styles.textOption}>
                                            {i18n.language === 'en'
                                                ? 'Favorites'
                                                : 'Favoris'
                                            }
                                        </p>
                                        <p className={styles.subtextOption}>
                                            {i18n.language
                                                ? 'List of your favorite posts.'
                                                : 'Liste de vos posts favoris.'
                                            }
                                        </p>
                                    </div>
                                </div>


                                <div style={{ marginBottom: '3%' }}>
                                    <span className={styles.titleAccount}>{t('language')}</span>
                                    <Select
                                        placeholder='Langue'
                                        options={languages}
                                        isSearchable={false}
                                        defaultValue={languages.filter(item => item.value == language)}
                                        onChange={newValue => {
                                            handleChangeLanguage(newValue.value)
                                            updateSetting({ user_langue: newValue.value, user_lang_session: newValue.value })
                                        }}
                                        className='select-language'
                                    />
                                </div>
                            </>
                        }

                        <div style={{ marginBottom: '3%' }}>
                            <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                                {t('subscribe_to_email_notification')}
                            </span>
                            <Select
                                placeholder='Langue'
                                options={suscriptions}
                                isSearchable={false}
                                defaultValue={suscriptions.filter(items => items.value === userEmailNotification)}
                                onChange={newValue => {
                                    setUserEmailNotification(newValue.value)
                                    updateSetting({ user_email_notification: newValue.value })
                                }}
                                className='select-language'
                            />
                        </div>


                        <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                            {t('community_Rules_and_Privacy_Policies')}
                        </span>

                        <br />

                        <Link to={'/legal/fr/conditions-generales'}
                            style={{ textDecoration: 'none', display: 'flex', color: '#222', alignItems: 'center', marginTop: '10px', marginBottom: '15px' }}>
                            <RiBookLine size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('terms_and_conditions')}
                            </span>
                        </Link>

                        {/* 
                        <a href={language === 'en' ? 'https://afdb.socialhub.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <AiOutlineSecurityScan size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('privacy_policy')}
                            </span>
                        </a> */}

                        <span style={{ fontSize: '13px', fontWeight: 'bold', color: color.primary }}>
                            {t('account')}
                        </span>

                        <br />

                        <div onClick={() => navigate(`/profiles/user/${user_id}/change-password`)} /*  to={`change-password`} */ style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '15px' }}>
                            <BiLockAlt size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('security_and_password')}
                            </span>
                        </div>

                        <div onClick={() => navigate(`/profile/${user_id}/transactions`, { state: { likes } })} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                            <IoWalletOutline size={26} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('balance')}
                            </span>
                        </div>

                        <Link
                            onClick={() => {
                                setShowAlert(true)
                            }}
                            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
                            <BsPatchCheck size={24} color='#808080' />
                            <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                                {t('subscriptions')}
                            </span>
                        </Link>


                        <ButtonSubmit
                            onClick={logoutAccount}
                            value={t('logout')}
                            isLoading={isLoading}
                            loadBoostrapColor={'light'}
                            scrollable={false}
                            style={{
                                width: '100%',
                                color: 'white',
                                marginBottom: '5%',
                                fontWeight: '600',
                                backgroundColor: color.primary
                            }}
                        />

                    </div>
                }
            </div>

            <ModelAlert
                message={language == 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />
        </div>
    )
}

export default Settings