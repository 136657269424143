import messagingKeys from "constants/messagingKeys";

export function generateChannelName(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
    }

    return result;
}

export const generateChatId = (type, conv_id, user_auth_id) => {
    if (type == messagingKeys.oneToOneChat && conv_id && user_auth_id) {
        const ids = [conv_id, user_auth_id]
        const chat_ids = ids?.sort((a, b) => a - b);
        return `chat_user_${chat_ids[0]}_and_user_${chat_ids[1]}`
    }
    if (type == messagingKeys.groupChat && conv_id) {
        return `group_chat_${conv_id}`
    }
    return null
}